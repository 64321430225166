import ReactPDF, { PDFViewer } from "@react-pdf/renderer";
import LorettaPDFViewer from "../../../util/components/LorettaPDFViewer";
import doc from '../../../util/content/pdf/manual_seguridad.pdf'

const Loretta3PDF = () => {

    // const renderPdf = () => {
    //     ReactPDF.render(<LorettaPDFViewer />, doc)
    // }

    return <>
        {/* <button onClick={renderPdf}>
            PDF
        </button> */}
        <PDFViewer>
            <LorettaPDFViewer />
        </PDFViewer>
    </>
}

export default Loretta3PDF