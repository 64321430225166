import { useDispatch, useSelector } from "react-redux";
import './css/services.css';
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { fetchServices } from "../slices/lorettaSlice";
import { Card, CardBody, CardSubtitle, CardTitle, CardText, CardLink, CardImg, CardFooter, CardHeader, Container } from "reactstrap";
import { iconFill } from "../../util/forms.util";


const Services = () => {
	// const {services} = props;
	const dispatch = useDispatch();
	const { services } = useSelector((state) => state.loretta);

	let icon =

		useEffect(() => {
			if (!services) dispatch(fetchServices())
		}, [])

	const getControl = (item) => {
		// return <div className="container">
		return <div className="card" style={{ width: '11rem' }}>
				<div className="card-body">
					<a href={item.imageSrc} target="_blank" className="btn px-3 ">
						{iconFill('bi-cash-coin', 30)}
						<h6 className="card-text">{item.tittle}</h6>
					</a>
				</div>
			</div>
		// </div>
	}

	return <>
	{/* <div class="col-sm">
      One of three columns
    </div>
    <div class="col-sm">
      One of three columns
    </div>
    <div class="col-sm">
      One of three columns
    </div> */}
	{services?.map((item, i) => {
				return <div className="col-sm" key={`divserv-${i}`}>{getControl(item)}</div>
			})}
	</>
}

export default Services;