export const formConstants = {

    FORMS: {
        "service": { fields: ["tittle", "image"], label: "Servicio", labels: "Servicios" },
        "newsletter": { fields: ["tittle", "subtittle", "desc", "files", "active"], label: "Boletín", labels: "Boletines", btNew: "Publicar y Enviar" },
        "article": { fields: ["tittle", "subtittle", "link", "image"], label: "Artículo", labels: "Artículos" },        
        "media": { fields: ["tittle", "file"], label: "Medio", labels: "Medios" },
        "banner": { fields: ["tittle", "image"], label: "Banner", labels: "Banners" },
        "customers": { fields: ["idDoc", "fullname", "phone", "email"], label: "Cliente", labels: "Clientes", btNew: "Guardar" },
        "cust-mail": { fields: ["email"] },
        // "company": { fields: ["emails", "phones", "whatsapp", "addresses"], label: "Empresa", labels: "Empresas" },
    },
    SERVICE: 'service',
    ARTICLE: 'article',
    NEWS: 'newsletter',
    MEDIA: 'media',
    BANNER: 'banner',

    CUSTOMER: 'customers',   
    
}