
export const getFileName = (fullPath) => {
    var filename = '';
if (fullPath) {
    var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
    filename = fullPath.substring(startIndex);
    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1);
    }
    return filename;    
}
}

export const formatDateDDMMYYYHHMM_min = (d) => {
    try {
        let ye = new Intl.DateTimeFormat('en', { year: '2-digit' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let hour = d.getHours();
        let min = d.getMinutes();
        return `${ye}${mo}${da}${hour}${min}`;
    } catch (e) {
        console.error(e);
        return d;
    }
}

export const formatDateDDMMYYYHHMM = (d) => {
    try {
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let hour = d.getHours();
        let min = d.getMinutes();
        return `${da}-${mo}-${ye} ${hour}:${min}`;
    } catch (e) {
        console.error(e);
        return d;
    }
}

export const formatDateDDMMYYY = (d) => {
    try {
        let ye = d ? new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d) : '';
        let mo = d ? new Intl.DateTimeFormat('en', { month: 'short' }).format(d) : '';
        let da = d ? new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d) : '';
        // let hour = d.getHours();
        // let min = d.getMinutes();
        return `${da}-${mo}-${ye}`;
    } catch (e) {
        // console.error(e);
        return d;
    }
}

export const formatDateHHMM = (d) => {
    try {
        let hour = d.getHours();
        let min = d.getMinutes();
        return `${hour}:${min}`;
    } catch (e) {
        console.error(e);
        return d;
    }
}
