import { Container } from "reactstrap";
import Home from "./Home";
import Services from "./Services";
import Media from './Media';
import Landing from "./Landing";
import Login from './Login';
import Web from "./Web";



const Wrapper = ({ content }) => {

  const getPage = () => {
    switch (content) {
      case 'home':
        return <Web />
      case 'services':
        return <Services/>
      case 'media':
        return <Media/>
      case 'landing':
        return <Landing/>
      case 'login':
        return <Login/>

      default:
        return <Web></Web>
        break;
    }
  }


  return <>{getPage()}</>
}

export default Wrapper;