import React from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Button,
} from 'reactstrap';
import logo from "../content/img/logo.png"
import { commonConstants } from '../constants/common.constants';

const LorettaNavBar = (props) => {
    const { handleClickEvent, signOutEvent, user } = props;
    const [isOpen, setIsOpen] = React.useState(false);

    const handleClick = (e, nav) => {
        e.preventDefault();
        //console.log(nav);
        handleClickEvent(nav);
    }

    const singOut = () => {
        signOutEvent();
    }

    const getLink = (item) => {
        // if(item.type === 'button'){
        //     return <NavLink href="login" ><Button color='danger'><i className="fa fa-user" aria-hidden="true">&nbsp;</i>Portal Empresarial</Button></NavLink>
        // }else{            
        return <NavLink key={item} onClick={(e) => { handleClick(e, item.nav) }}>{item.label}</NavLink>
        // }
        // return <NavLink key={item} href={item.nav}>{item.label}</NavLink>
    }

    return (
        <div style={{
            display: 'block', width: '100%'
            // , padding: 10
        }}>

            <Navbar color="dark" dark expand="md">
                <NavbarBrand style={{ position: 'absolute', top: 0, zIndex: 1 }}>
                    <img
                        alt="logo"
                        src={logo}
                        width={'150em'}
                        className='img-fluid img-logo'
                    />
                </NavbarBrand>
                <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />

                <Collapse isOpen={isOpen} navbar className='nav justify-content-end navbar-nav'>
                    {
                        user ?
                            <>
                                {user.email}
                                <NavItem>
                                    <NavLink href="#" key="logout" ><Button color='danger' onClick={singOut}><i className="fa fa-sign-out" aria-hidden="true">&nbsp;</i>Cerrar sesión</Button></NavLink>
                                </NavItem>
                            </> :
                            <>
                                <Nav id="menu">
                                    {
                                        commonConstants.MENU.map((item) => {
                                            return <NavItem key={`navitem-${item.nav}`}>
                                                {getLink(item)}
                                            </NavItem>
                                        })
                                    }
                                </Nav>
                                <NavItem>
                                    <NavLink href="login" key={commonConstants.LOGIN} ><Button color='danger' onClick={(e) => { handleClick(e, commonConstants.LOGIN) }}><i className="fa fa-user" aria-hidden="true">&nbsp;</i>Portal Empresarial</Button></NavLink>
                                </NavItem>
                            </>
                    }

                </Collapse>
            </Navbar>
        </div >
    );
}

export default LorettaNavBar;