import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';
import defaultImg from '../content/img/Banner Outsourcing.png'
// import logo from "../content/img/banner2.png"
// import logo from "https://picsum.photos/id/42/1200/400";


const LorettaBanner = (args) => {
    const {items} = args;
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
  
    const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    };
  
    const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    };
  
    const goToIndex = (newIndex) => {
      if (animating) return;
      setActiveIndex(newIndex);
    };
  
    const slides = items.length > 0 ? items.map((item) => {
      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={item.imageSrc}          
          >
            <img src={item.imageSrc || defaultImg} alt={item.tittle} className='img-responsive'/>

          {/* <img src={item.imageSrc ? item.imageSrc : logo} alt={item.tittle} /> */}
          
          {/* <img src={item.imageSrc} alt={item.tittle} /> */}
          {/* <div className='loretta-banner'>algo</div> */}
          <div className='carousel-caption-div'>
            <CarouselCaption
            captionText=""
            captionHeader=""
          />
          </div>
          
        </CarouselItem>
      );
    }) : [<CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={'defaultIm'}          
          >
            <img src={defaultImg}  className='img-responsive'/>
          <div className='carousel-caption-div'>
            <CarouselCaption
            captionText=""
            captionHeader=""
          />
          </div>    
  </CarouselItem>];
  
    return (
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        {...args}
      >
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    );
}

export default LorettaBanner;