import firebase from 'firebase/compat/app'
import 'firebase/compat/auth';


const firebaseConfig = {
    apiKey: "AIzaSyCu9wk_N0q3WaVPOGgtrSXQFau4yQ3dk5U",
  authDomain: "loretta3-congroup.firebaseapp.com",
  projectId: "loretta3-congroup",
  storageBucket: "loretta3-congroup.appspot.com",
  messagingSenderId: "915645940572",
  appId: "1:915645940572:web:3908e69796936df76b7799",
  measurementId: "G-Q5Q2J9T76M"

    // apiKey: "AIzaSyC329Z6oyA1rzCt3gSwxkXH691o2dJpYtA",
    // authDomain: "etechne-congroup.firebaseapp.com",
    // projectId: "etechne-congroup",
    // storageBucket: "etechne-congroup.appspot.com",
    // messagingSenderId: "105708128160",
    // appId: "1:105708128160:web:6970d65db72147ce6386c5",
    // measurementId: "G-GEBNNF7GVH"
};


try {
    firebase.initializeApp(firebaseConfig);
} catch (err) {
    if (!/already exists/.test(err.message)) {
        console.error('Firebase initialization error', err.stack);
    }
}

const fire = firebase;
export default fire;