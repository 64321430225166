import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { commonConstants } from "../../../util/constants/common.constants";
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row } from "reactstrap";
import { formConstants } from "../../../util/constants/form.constans";
import { useForm } from "react-hook-form";
import { initForm, objAdded, storageFile, storageImage, stsChange, fetchData, objDeleted, objChange, objUpdated } from "../../slices/lorettaSlice";
import { initialContent, initialCustomer } from "../../../util/forms.util";

const Show = (props) => {

    const defineParms = (props) => {
        const { id, coll, idForm } = props;

        const locSeacrh = new URLSearchParams(location.search);

        const coll_ = locSeacrh.get('coll');
        const id_ = locSeacrh.get('id');
        const idForm_ = locSeacrh.get('idForm');

        var coll__ = coll ? coll : coll_;
        var id__ = id ? id : id_;
        var idForm__ = coll ? idForm : idForm_;

        return { id: id__, coll: coll__, idForm: idForm__ }
    }

    const location = useLocation();
    const { id, coll, idForm } = defineParms(props, location);

    const { obj, form } = useSelector((state) => state.loretta);

    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        // obj = null;
        if (!form) {
            dispatch(initForm({ idForm }));
        }
        if (!obj) {
            dispatch(fetchData({ coll, id }));
        }
    }, [])

    const onSubmit = (data, e) => {
        var defaultObj = {};

        if (idForm == formConstants.SERVICE || idForm == formConstants.ARTICLE) {
            if (!data.image[0]) {
                dispatch(stsChange({ name: "error", value: { message: "Adjunte la imagen" } }))
                return;
            };

            defaultObj = initialContent({ contentType: idForm });
            var obj = {
                ...defaultObj,
                ...data,
                image: data.image[0],
            }
            // delete obj.image;
            dispatch(storageImage(obj));
        }

        switch (idForm) {
            case formConstants.CUSTOMER:
                defaultObj = initialCustomer();
                var obj = {
                    id,
                    ...defaultObj,
                    ...data,
                }
                // dispatch(objAdded({ coll: coll, obj }));
                dispatch(objUpdated({ coll: coll, obj }));
                break;

            case formConstants.MEDIA:
                if (!data.file[0]) {
                    dispatch(stsChange({ name: "error", value: { message: "Adjunte la imagen" } }))
                    return;
                };

                defaultObj = initialContent({ contentType: idForm });
                var obj = {
                    ...defaultObj,
                    ...data,
                    file: data.file[0],
                }
                // delete obj.image;
                dispatch(storageFile(obj));
                break;

            case formConstants.BANNER:
                defaultObj = initialContent({ contentType: 'banner' });
                var obj = {
                    ...defaultObj,
                    ...data,
                    image: data.image[0],
                }
                // dispatch(objAdded({ coll: coll, obj }));
                dispatch(storageImage(obj));
                break;
            default:
                break;
        }
        e.target.reset();
    }

    const getFields = () => {
        let fields = Object.entries(form?.fields)
            .map(([key, value]) => value);
        return fields;
    }

    const handleChange = (e) => {
        dispatch(stsChange(e.target.name, e.target.value))
    }

    const getControl = ({ controlId, labelES, placeholder, type }) => {
        let value = obj[controlId] || JSON.stringify(obj[controlId]);

        return <FormGroup key={`group${controlId}`}>
            <Label htmlFor={controlId}>
                {labelES}
            </Label>
            <input className="form-control"
                id={controlId}
                name={controlId}
                placeholder={placeholder || ''}
                type={type}
                autoComplete="false"
                // value= {value}
                // onChange={(e) => handleChange(e)}
                // {...register(controlId)}

                {...register(controlId,
                    { value: obj[controlId] },
                    { onChange: (e) => handleChange(e) })
                } />
        </FormGroup>
    }


    const buttonBar = (fetch, remove, urlList) => {
        let bar = <></>;
        switch (coll) {
            case 'content':
                bar = <div className="row action-bar">
                    <div className="col-12">
                        {urlList && <Button
                            onClick={() => window.location = urlList}
                            className="button-primary"
                        >
                            <span className="btn-inner--icon">
                                <i className="fa fa-rotate-right" />&nbsp;
                            </span>
                            Volver a la lista
                        </Button>
                        }
                        {remove && <Button
                            onClick={(e) => remove(e, coll, id)}
                            className="button-primary"
                        >
                            <span className="btn-inner--icon">
                                <i className="fa fa-trash" />&nbsp;
                            </span>
                            {commonConstants.LBL_DEL}
                        </Button>}

                    </div>
                </div>
                break;
            case commonConstants.COLL_CUSTOMERS:
                bar = <div className="row action-bar">
                    <div className="col-12">
                        {urlList && <Button
                            onClick={() => window.location = urlList}
                            className="button-primary"
                        >
                            <span className="btn-inner--icon">
                                <i className="fa fa-rotate-right" />&nbsp;
                            </span>
                            Volver a la lista
                        </Button>
                        }
                        {remove && <Button
                            onClick={(e) => remove(e, coll, id)}
                            className="button-primary"
                        >
                            <span className="btn-inner--icon">
                                <i className="fa fa-trash" />&nbsp;
                            </span>
                            {commonConstants.LBL_DEL}
                        </Button>}

                    </div>
                </div>
                break;

            default:
                break;
        }
        return bar;

    }

    const remove = (e, coll, id) => {
        e.preventDefault();
        dispatch(objDeleted({ coll, id }))
    }

    return <>
        {
            obj?.id ? <>
                {buttonBar(null, remove, `../${commonConstants.URL_DATA_LIST}/?coll=${coll}&list=true&idForm=${idForm}`)}
                {/* <h2>{`ID Del registro: ${obj.id.toUpperCase()}`}</h2>
                <pre>{JSON.stringify(obj, null, 10)}</pre> */}

                <Row>
                    <Col className="col-md-8 col-xs-12">
                        <Card>
                            <CardBody>
                                {
                                    form ? <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                                        {getFields().map((field) => {
                                            return getControl(field);
                                        })}
                                        {/* {form.btNew &&
                                            <Button className="btn-danger btAdd">
                                                {form.btNew}
                                            </Button>
                                        } */}
                                    </Form> : <pre>{JSON.stringify(obj, null, 10)}</pre>
                                }


                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </> :
                <>
                    {commonConstants.MSG_EMPTY_DATA_}
                </>
        }
    </>
}

export default Show;