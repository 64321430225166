import LorettaFooter from '../../util/components/LorettaFooter';
import LorettaNavBar from '../../util/components/LorettaNavBar';
import LorettaBanner from '../../util/components/LorettaBanner';
import './css/web.css'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchArticles, fetchBanner, fetchMedia, fetchNews, fetchServices } from '../slices/lorettaSlice';
import Services from './Services';
import LorettaArticle from '../../util/components/LorettaArticle';
import Articles from './Articles';
import Media from './Media';
import News from './News';
import LorettaPopUp from '../../util/components/LorettaPopUp';

const Web = () => {

    const dispatch = useDispatch();
    const { banners, articles, services, media, news, online, infoLoretta } = useSelector((state) => state.loretta);

    useEffect(() => {
        if (!banners) dispatch(fetchBanner());
        if (!articles) dispatch(fetchArticles());
        // if (!mediaAndNews) dispatch(fetchMediaAndNews());
        if (!services) dispatch(fetchServices());
        if (!media) dispatch(fetchMedia());
        if (!news) dispatch(fetchNews());
    }, [])

    return <>

        <div className="text-center">
            <LorettaPopUp item={{ imageSrc: "" }} />

            <div className="row loretta3-banner">
                <div className="col">
                    <LorettaBanner items={banners || []} />
                </div>
            </div>
            <div className='row' >
                <div className="container serv">
                    <div className="row loretta3-services">
                        <Services></Services>
                    </div>
                </div>

                <div className='col-2'>
                    <div className='loretta3-media'>
                        <Media />
                    </div>
                    <div className='loretta3-news'>
                        <News />
                    </div>
                </div>
                <div className='col-10'>
                    <div className="container serv">
                        <div className="row loretta3-articles">
                            <Articles></Articles>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </>;
}

export default Web;