import { useState } from "react";
import ReactPlayer from "react-player/lazy";
import { Link } from "react-router-dom";
import { Card, CardBody, CardSubtitle, CardTitle, CardText, CardLink, CardImg, CardFooter } from "reactstrap";

const LorettaPopUp = ({ item }) => {

  const [visible, setVisible] = useState(true);

  // return <div id="popup-video" className="overlay">
  //   <div className="popup">
  //     <a className="close" href="#">&times;</a>
  //     {/* <ReactPlayer url='https://www.youtube.com/watch?v=OuSXpJbMeRw' />       */}
  //     <ReactPlayer url='https://www.youtube.com/watch?v=OuSXpJbMeRw'playing={true} volume={1} muted={true} controls={true}/>      
  //   </div>
  // </div>
  const vis_ = visible === true ? 'block' : 'none'
  const style = {
    display: vis_
  }
  const handleClose = (e) => {
    e.preventDefault();
    setVisible(false);
  }

  return <>  
    {/* <div className="div-loading-full" style={{style}}>
      <div className="popup">
        <a className="close" href="#" onClick={(e) => handleClose(e)}>&times;</a>
        <ReactPlayer url='https://www.youtube.com/watch?v=OuSXpJbMeRw' playing={true} volume={1} muted={true} controls={true} />
      </div>

    </div> 

    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
      Launch demo modal
    </button>

    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
          ...
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" className="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>*/}
    {/* </div> */}
  </>

}

export default LorettaPopUp;