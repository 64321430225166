import { configureStore } from "@reduxjs/toolkit";
import lorettaSlice from "./features/slices/lorettaSlice";
// import etechneSlice from "./features/slices/etechneSlice";


const store = configureStore({
  reducer: {
    loretta: lorettaSlice,    
    // etechne: etechneSlice
  }
})

export default store;

