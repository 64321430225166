import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row, Form, FormGroup, FormText, Input, Label, Button } from "reactstrap";
import { initForm, objAdded, storageFile, storageImage, stsChange } from "../../slices/lorettaSlice";
import { initialContent, initialCustomer } from "../../../util/forms.util";
import { commonConstants } from "../../../util/constants/common.constants";
import { formConstants } from "../../../util/constants/form.constans";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const Add = (props) => {


    const defineParms = (props) => {
        const { id, coll, idForm } = props;

        const locSeacrh = new URLSearchParams(location.search);

        const coll_ = locSeacrh.get('coll');
        const id_ = locSeacrh.get('id');
        const idForm_ = locSeacrh.get('idForm');

        var coll__ = coll ? coll : coll_;
        var id__ = id ? id : id_;
        var idForm__ = coll ? idForm : idForm_;

        return { id: id__, coll: coll__, idForm: idForm__ }
    }

    const location = useLocation();
    const { id, coll, idForm } = defineParms(props, location);

    const { form } = useSelector((state) => state.loretta);

    const dispatch = useDispatch();


    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (!form) {
            dispatch(initForm({ idForm }));
        }
    }, [])

    const onSubmit = (data, e) => {
        var defaultObj = {};

        if(idForm == formConstants.SERVICE || idForm == formConstants.ARTICLE){
            if (!data.image[0]) {
                dispatch(stsChange({ name: "error", value: { message: "Adjunte la imagen" } }))
                return;
            };

            defaultObj = initialContent({ contentType: idForm });
            var obj = {
                ...defaultObj,
                ...data,
                image: data.image[0],
            }
            // delete obj.image;
            dispatch(storageImage(obj));            
        }

        switch (idForm) {
            case formConstants.CUSTOMER:
                defaultObj = initialCustomer();
                var obj = {
                    ...defaultObj,
                    ...data,
                }
                dispatch(objAdded({ coll: coll, obj }));
                break;            

            case formConstants.MEDIA:
                if (!data.file[0]) {
                    dispatch(stsChange({ name: "error", value: { message: "Adjunte la imagen" } }))
                    return;
                };
    
                defaultObj = initialContent({ contentType: idForm });
                var obj = {
                    ...defaultObj,
                    ...data,
                    file: data.file[0],
                }
                // delete obj.image;
                dispatch(storageFile(obj));           
                break;

            case formConstants.BANNER:
                defaultObj = initialContent({ contentType: 'banner' });
                var obj = {
                    ...defaultObj,
                    ...data,
                    image: data.image[0],
                }
                // dispatch(objAdded({ coll: coll, obj }));
                dispatch(storageImage(obj));
                break;           
            default:
                break;
        }
        e.target.reset();
    }

    const getFields = () => {
        let fields = Object.entries(form?.fields)
            .map(([key, value]) => value);
        return fields;
    }

    const getControl = ({ controlId, labelES, placeholder, type }) => {
        return <FormGroup key={`group${controlId}`}>
            <Label htmlFor={controlId}>
                {labelES}
            </Label>
            <input className="form-control"
                id={controlId}
                name={controlId}
                placeholder={placeholder || ''}
                type={type}
                autoComplete="false"
                {...register(controlId)}
            />
        </FormGroup>
    }


    const buttonBar = (urlList) => {
        let bar = <></>;
        switch (coll) {
            case 'content':
                bar = <div className="row action-bar">
                    <div className="col-12">
                        {urlList && <Button
                            onClick={() => window.location = urlList}
                            className="button-primary"
                        >
                            <span className="btn-inner--icon">
                                <i className="fa fa-rotate-right" />&nbsp;
                            </span>
                            Volver a la lista
                        </Button>
                        }
                    </div>
                </div>
                break;
            default:
                bar = <div className="row action-bar">
                    <div className="col-12">
                        {urlList && <Button
                            onClick={() => window.location = urlList}
                            className="button-primary"
                        >
                            <span className="btn-inner--icon">
                                <i className="fa fa-rotate-right" />&nbsp;
                            </span>
                            Volver a la lista
                        </Button>
                        }

                    </div>
                </div>
                break;
        }
        return bar;
    }



    return <Container>
        {buttonBar(`../${commonConstants.URL_DATA_LIST}/?coll=${coll}&list=true&idForm=${idForm}`)}

        {
            form &&
            <>
                <Row>
                    <h1>Registrar nuevo {form.label}</h1>
                </Row>
                <Row>
                    <Col className="col-md-8 col-xs-12">
                        <Card>
                            <CardHeader>

                            </CardHeader>
                            <CardBody>
                                <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                                    {getFields().map((field) => {
                                        return getControl(field);
                                    })}
                                    <Button className="btn-danger btAdd">
                                        {form.btNew}
                                    </Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>

        }


    </Container>
}

export default Add;