import { render } from "@testing-library/react";
import React from "react";
import { Alert } from "reactstrap";
import LorettaLoading from "./LorettaLoading";

const LorettaBoundary = (props) => {
    const { dispatch, loading, info, error, infoLoretta, handleCloseClick } = props;
    
    const getInfoAlert = () => {
        setTimeout(function(){
            handleCloseClick();
        }, 5000)

        return <Alert color="info" className="alert alert-info alert-dismissible fade show">
            <span className="alert-icon"><i className="ni ni-like-2"></i></span>
            <span className="alert-text"><strong>Información: </strong> {info} </span>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleCloseClick}>
                {/* <span aria-hidden="true">&times;</span> */}
            </button>
        </Alert>
    }

    const getErrorAlert = () => {

        setTimeout(function(){
            handleCloseClick();
        }, 5000)

        // if(error?.name = 'FirebaseError'){
            //console.log(error);
        // }        

        return <Alert color="warning" className="alert alert-warning alert-dismissible fade show">
            <span className="alert-icon"><i className="ni ni-like-2"></i></span>
            <span className="alert-text"><strong>Error!</strong> {error}</span>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleCloseClick}>
                {/* <span aria-hidden="true">&times;</span> */}
            </button>
        </Alert>
    }

    const getStatusBar = () => {

       return <Alert color="secondary" className="alert alert-secondary alert-dismissible fade show">
            <span className="alert-icon"><i className="fa fa-envelope"></i></span>&nbsp;
            <span className="alert-text"> Mensaje del sistema &nbsp; <strong>{infoLoretta}</strong></span>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleCloseClick}>
                {/* <span aria-hidden="true">&times;</span> */}
            </button>
        </Alert>
    }

    return <>
        {loading && <LorettaLoading />}      

        {info && getInfoAlert()}

        {error && getErrorAlert()}

        {/* {getStatusBar()} */}

    </>
}

export default LorettaBoundary;