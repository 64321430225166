import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, FormGroup, Label, Row, Table } from "reactstrap";
import { addFile, objAdded, removeFile } from "../../features/slices/lorettaSlice";
import { initialFile } from "../forms.util";
import { useState } from "react";
import { Link } from "react-router-dom";


const LorettaFilesUp = ({idForm}) => {

    const [fileName, setFileName] = useState("");
    const [fileSrc, setFileSrc] = useState("");
    const [file, setFile] = useState(null);

    const { files } = useSelector((state) => state.loretta);
    const dispatch = useDispatch();

    const onSubmit = (e) => {
        e.preventDefault();

        let defaultFile = initialFile();
                
        let file_ = {
            ...defaultFile,
            fileName,
            file,
            fileSrc
        }

        dispatch(addFile({contentType: idForm,  file: file_}));
        setFileName('');
        setFile(null);
        setFileSrc('');
    }

    const handleChange = (e) => {
        setFileName(e.target.value);
    }

    const handleChangeFile = (e) => {
        setFile(e.target.files[0]);
        setFileSrc(e.target.value);
        // this.setState({ ...this.state, [e.target.name]: e.target.files[0] });
    }

    const getControl = (el, i) => {
       //console.log(el);

        return <tr key={i}>
                    <th scope="row">
                        {i}
                    </th>
                    <td>
                        {el.fileName}
                    </td>
                    <td className="text-cutted">
                             <Link to={el.fileSrc} target="_blank">
                                            <span>
                                                <i className="fa fa-download"></i>
                                            </span>
                                        </Link>

                        <Link />
                        {/* {el.file?.name} */}
                    </td>
                    <td>
                    <Link onClick={(e) => dispatch(removeFile({fileSrc: el.fileSrc ,i}))}>
                                    <span className="btn-inner--icon">
                                        <i className="fa fa-trash" />
                                    </span>
                                </Link>
                    </td>
                </tr>               
    }

    return <><div className="uploadForm">
        <h4>Cargar Archivos</h4>
        <Table
        >
            <thead>
                <tr>
                    <th>
                        #
                    </th>
                    <th>
                        Texto en el link
                    </th>
                    <th className="text-cutted">
                        Archivo
                    </th>
                </tr>
            </thead>
            <tbody>
                {files.map((el, i) => {
                    let tr= getControl(el, i);
                    return tr;
                })}       
            </tbody>
        </Table>

        <Row className="div-file">
            <Col className="col-10">            
                    
                    <FormGroup>
                    <label htmlFor="fileName">Nombre del archivo</label>
                        <input className="form-control"
                            id="fileName"
                            name="fileName"
                            placeholder="Texto del link"
                            type="text"
                            autoComplete="false"
                            onChange={handleChange}
                    value={fileName}       
                        />
                    </FormGroup>           
                    <FormGroup>

                <Label htmlFor="file">
                    {/* {fileSrc || 'Seleccione el Archivo'} */}
                    Seleccione el Archivo
                </Label>
                <input className="form-control"
                    id="file"
                    name="file"
                    type="file"  
                    value={fileSrc}                    
                    onChange={handleChangeFile}                            
                />                
            </FormGroup>
            </Col>
            <Col className="col-2">
                <Button 
                // disabled
                    onClick={(e) => onSubmit(e)}
                    className="button-primary btAdd"
                >
                    <span className="btn-inner--icon">
                        <i className="fa fa-plus" />&nbsp;
                    </span>

                </Button>
            </Col>
        </Row>




    </div></>
}

export default LorettaFilesUp;