import { Alert, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import React from "react";
import { commonConstants } from "../../util/constants/common.constants";
import { sendMail, stsChange } from "../slices/lorettaSlice";
import { formatDateDDMMYYYHHMM } from "../../util/common.util";
// import { LttMap, LttCRUDForm} from '../../util/components';
import LttMap from "../../util/components/LttMap";
import LttCRUDForm from "../../util/components/LttCRUDForm";


const Landing = () => {

    const { info : mail, obj } = useSelector((state) => state.loretta);    

    const dispatch = useDispatch();

    // const obj = useSelector((state) => state.landing);
    const formsConstants = commonConstants.FORM_LANDING;


    const handleSendMail = (data, e) => {
        //console.log(data);

        let obj = {
            email: data.email,
            fullname: data.fullname,
            message: data.message,
            phone: data.phone,
            mailTo: [data.email, 'lorenita.serrano@gmail.com', 'congroupweb@gmail.com'], //'congroup@gmail.com'
            dateIn: formatDateDDMMYYYHHMM(new Date())
        }
        let doc = {
            to: obj.mailTo,
            message: {
                subject: `Mensaje enviado desde la página de Congroup SAS`,
                // text: 'This is the plaintext section of the email body.',
                html: `<p>De donde nos escribes: ${obj.fullname}</p>
                <p>Teléfono: ${obj.phone}</p>
                <p>Email ${obj.email}</p>
                <p>En que te podemos colaborar: ${obj.message}</p>      
            <p>&nbsp;</p>
            <br/>              
            <div style="background:#eee;border:1px solid #ccc;padding:5px 10px;"><span style="color: rgb(34, 34, 34); font-family: Arial, Helvetica, sans-serif; font-size: small;">Mensaje enviado por Congroup Portal WEB,</span><br style="color: rgb(34, 34, 34); font-family: Arial, Helvetica, sans-serif; font-size: small;" />
            <span style="color: rgb(34, 34, 34); font-family: Arial, Helvetica, sans-serif; font-size: small;">Administraci&oacute;n</span></div>            
            `,
            }
        }
        dispatch(sendMail(doc))
    }

    const handleCloseClick = () => {
        dispatch(stsChange({ name: "mail", value: null }))
    }


    return <div>
      <Row>
            <Col className="col-md-6 col-xs-12">
                <Row>
                    <Col className='w-100'>
                        <Card body color="secondary" outline>
                            <CardHeader>
                                <Row>
                                    <Col className="col-2">
                                        <span className="btn-inner--icon">
                                            <i className="ni ni-bullet-list-67" />
                                        </span>
                                    </Col>
                                    <Col><div className="tittle-bar">
                                        <h2>Dejanos tu mensaje</h2>
                                    </div> <br /></Col>
                                </Row>
                            </CardHeader>

                            <CardBody>
                                
                                <div className="container">
                                    <div className="row">                                        
                                        <LttCRUDForm onClickEvent={handleSendMail} obj={obj} formFields={formsConstants} urlList={''}
                                            readOnly={false} />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>

                    </Col>
                    {/* <LttMap/> */}
                </Row>
            </Col>
            <Col className="col-md-6 col-xs-12">
                {/* <iframe src="https://maps.app.goo.gl/6BKUjN4tt6vxGik48" width="640" height="480"></iframe> */}
                <LttMap />
            </Col>
        </Row>


    </div>
}

export default Landing;