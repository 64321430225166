import { Row, Col, Button } from "reactstrap";
import { commonConstants } from "./constants/common.constants";
import { formConstants } from "./constants/form.constans";

export const f1 = () => {
    return;
}

export const initialContent = ({ contentType }) => {

    const payload = {
        tittle: '',
        subtittle: '',
        desc: '',
        contentType: contentType ? contentType : "",

        active: true,

        files: [], //{fileName: "",fileSrc: ""}


        fileName: "",
        fileSrc: "",
        imageSrc: "",
        videoSrc: "",

        startAt: new Date(),
        endAt: new Date(),
        createdAt: new Date(),
        createdBy: "admin",
    }

    return payload;
}

export const initialFile = () => {
    const payload = { fileName: "", fileSrc: "" }
    return payload;
}

const meta = (controlId) => {
    switch (controlId) {
        case "idDoc":
            return inputField({ order: 10, controlId: "idDoc", type: "number", labelES: "Número de Id." });
        case "doc":
            return inputField({ order: 20, controlId: "doc", type: "text", labelES: "# Doc", placeHolder: "" });
        case "fullname":
            return inputField({ order: 20, controlId: "fullname", type: "text", labelES: "Nombre", placeHolder: "" });
        case "lastname":
            return inputField({ order: 30, controlId: "lastname", type: "text", labelES: "Apellido", placeHolder: "" });
        case "phone":
            return inputField({ order: 60, controlId: "phone", type: "number", labelES: "Teléfono" });
        case "email":
            return inputField({ order: 70, controlId: "email", type: "email", labelES: "Correo Electrónico" });

        case "tittle":
            return inputField({ order: 10, controlId: "tittle", type: "text", labelES: "Titulo", labelHD: "Titulo", placeHolder: "" });
        case "subtittle":
            return inputField({ order: 20, controlId: "subtittle", type: "text", labelES: "Subtitulo", labelHD: "Subtitulo", placeHolder: "" });
        case "desc":
            return inputField({ order: 30, controlId: "desc", type: "text", labelES: "Descripción" });
        case "link":
            return inputField({ order: 10, controlId: "link", type: "text", labelES: "Link", labelHD: "Link", placeHolder: "" });
        case "imageSrc":
            return inputField({ order: 10, controlId: "imageSrc", type: "text", labelES: "Link de la Imagen", labelHD: "Link", placeHolder: "" });
        case "image":
            return inputField({ order: 40, controlId: "image", type: "file", labelES: "Imagen", labelHD: "_" });
        case "file":
            return inputField({ order: 50, controlId: "file", type: "file", labelES: "Archivo", labelHD: "_" });
        case "files":
            return inputField({ order: 50, controlId: "files", type: "files", labelES: "Archivo", labelHD: "Archivos" });
            case "active":
            return inputField({ order: 10, controlId: "active", type: "check", labelES: "Activo", labelHD: "Activo", placeHolder: "" });

        default:
            return inputField({ order: 10, controlId: { controlId }, type: "text", labelES: { controlId }, placeHolder: "", active: true, activeHD: true });
    }
}

export const initialForm = (idForm) => {

    const form_ = formConstants.FORMS[idForm];

    if (form_) {
        const fields = {};
        form_?.fields.map((el) => {//el es el conhtrolId
            fields[el] = meta(el);
        })

        let btNew = form_.btNew || 'Publicar';

        const form = {
            ...form_,
            btNew,
            fields
        }
        return form;
    }
    return null;


}

const inputField = (props) => {
    const { type, labelES, labelHD, placeHolder } = props;
    var payload = {};

    if (type == 'files') {
        payload = {
            htmlControl: "files",
            active: true,
            activeHD: false,

            ...props,
            labelES: labelES || '',
            labelHD: labelHD || labelES || '',
            placeholder: placeHolder || '',
        }
        return payload;
    }

    payload = {
        htmlControl: "input",
        active: true,
        activeHD: false,

        ...props,
        labelES: labelES || '',
        labelHD: labelHD || labelES || '',
        placeholder: placeHolder || '',
    }
    return payload;
}


export const initialCustomer = () => {

    const payload = {
        // coll: commonConstants.COLL_CUSTOMERS,
        active: true,
        createdAt: new Date(),
        createdBy: "admin",
    }

    return payload;
}

export const scrollTop = () => {
    return <div id="scroll-Top">
        <div className="return-to-top">
            <i className="fa fa-angle-up " id="scroll-top" data-toggle="tooltip" data-placement="top" title="" data-original-title="Back to Top" aria-hidden="true"></i>
        </div>
    </div>
}

export const whatsAppBt = ({ whatsapp }) => {
    return <>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
        <a href={`https://api.whatsapp.com/send?phone=${whatsapp}&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n`} className="float" target="_blank">
            <i className="fa fa-whatsapp my-float"></i>
        </a>
    </>
}

export const breadcrumb = () => {
    return <div className='action-bar-left'>
        <Row>

            <Col>
                <a href={`../`} className="button "><span className="btn-inner--icon">
                    <i className="fa fa-star" />
                </span> {`Inicio`} </a>
            </Col>
            <Col>
                <Button outline
                    color="white"
                    onClick={() => window.location = `../${commonConstants.URL_NEWS_ADD}`}
                >
                    <span className="btn-inner--icon">
                        <i className="fa fa-plus" />&nbsp;
                    </span>
                    {`Crear y enviar Boletin`}
                </Button>
            </Col>
        </Row>
    </div>
}


export const metaMail = (obj) => {

    let obj_ = {
        ...obj,
        // mailTo: ['congroupweb@gmail.com, lorenita.serrano@gmail.com, serrano.lorena@loretta3.com'],
        // mailTo: toMail,
        mailTo: []
    }

    const getLink = (file) => {
        return `<p style="text-align: center;"> 
                <a className="btn" href=${file.fileSrc} target="_blank" style="display: inline-block;
                background-color: #f44336;
                color: #FFFFFF;
                padding: 14px 25px;
                text-align: center;
                text-decoration: none;
                font-size: 16px;
                margin-left: 20px;
                opacity: 0.9;"> 

                <span className="btn-inner--icon">
                <i className="fa fa-search" /> VER ${file.fileName}
                </span>
                
                </a></p>`
    }

    const mailtmp = ({ tittle, subtittle, desc, files, fileSrc }) => {
        // let logo_ = {...logo}
        // let logo_ = 'gs://etechne-congroup.appspot.com/logo.png';
        let logo_ = 'https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/logo.png?alt=media&token=42fa3fcc-d5a7-4f53-9343-733e9d3e4352'
        const html = `<h1 style="color: #5e9ca0;"><strong><img src="${logo_}" style="margin: 0 auto; display: block; width: 50%; height: 50%;"></strong></h1>
        <h1 style="color: rgb(94, 156, 160); text-align: center;">${tittle}</h1>
        <h2 style="color: rgb(46, 108, 128); text-align: center;">${subtittle}</h2>
        <p style="text-align: center;"><span style="text-align: start;color: rgb(106, 113, 140);background-color: rgb(255, 255, 255);font-size: 18px;">${desc}</span></p>        
        
        ${files?.map((el) => {
            return getLink(el);
        })
            }        

        <div style="background:#eee;border:1px solid #ccc;padding:5px 10px;"><span style="color: rgb(34, 34, 34); font-family: Arial, Helvetica, sans-serif; font-size: small;">Mensaje enviado por Congroup Portal Empresarial,</span><br style="color: rgb(34, 34, 34); font-family: Arial, Helvetica, sans-serif; font-size: small;"><span style="color: rgb(34, 34, 34); font-family: Arial, Helvetica, sans-serif; font-size: small;">Administraci&oacute;n https://loretta3-congroup.web.app/</span></div>`;
        return html;
    }

    const html_ = mailtmp(obj);

    let doc = {
        to: ['portal@congroupsas.com'],
        message: {
            subject: `Boletin Congroup SAS`,
            // attachments: [logo],
            // text: 'This is the plaintext section of the email body.',
            html: html_,
        }
    }

    return doc;
}

export const iconFill = (key, size) => {
    size = size ? size : 16;
    let icon = "";
    switch (key) {
        case "bi-cash-coin":
            icon = <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="currentColor" className="bi bi-cash-coin" viewBox={`0 0 16 16`}>
                <path fillRule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8m5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0" />
                <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195z" />
                <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083q.088-.517.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1z" />
                <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 6 6 0 0 1 3.13-1.567" />
            </svg>
            break;

        case "bi-bank":
            icon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bank" viewBox="0 0 16 16">
                <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.5.5 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72z" />
            </svg>
            break;

        default:
            icon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-0-square-fill" viewBox="0 0 16 16">
                <path d="M8 4.951c-1.008 0-1.629 1.09-1.629 2.895v.31c0 1.81.627 2.895 1.629 2.895s1.623-1.09 1.623-2.895v-.31c0-1.8-.621-2.895-1.623-2.895" />
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm5.988 12.158c-1.851 0-2.941-1.57-2.941-3.99V7.84c0-2.408 1.101-3.996 2.965-3.996 1.857 0 2.935 1.57 2.935 3.996v.328c0 2.408-1.101 3.99-2.959 3.99" />
            </svg>
            break;
    }
    return icon;
}

export const iconArrowUpSquareFill = (size) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="#cc3508" className="bi bi-arrow-up-right-square-fill" viewBox="0 0 16 16">
        <path d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707" />
    </svg>
}
