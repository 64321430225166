import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchArticles, fetchMedia, fetchServices } from "../slices/lorettaSlice";
import { Col, Row } from "reactstrap";
import LorettaContentMin from "../../util/components/LorettaContentMin";
import { iconArrowUpSquareFill, iconFill } from "../../util/forms.util";

const Media = () => {

    const dispatch = useDispatch();
    const { media } = useSelector((state) => state.loretta);

    useEffect(() => {
        if (!media) dispatch(fetchMedia())
    }, [])

    return <div>
        <Row>
            <Col className="">
                <h4>Medios</h4>
                <Row

                >
                    {media?.map((item, i) => {
                        return <div className="d-flex flex-column flex-md-row align-items-center" key={`divmed${i}`} id={`divmed${i}`}>
                            <div className="list-group">
                                <a href={item.fileSrc} target="_blank" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                                    {iconArrowUpSquareFill(20)}
                                    <div className="d-flex gap-2 w-100 justify-content-between">
                                        <div>
                                            <p className="mb-0">{item.tittle}</p>                                            
                                        </div>                                        
                                    </div>
                                </a>
                            </div>
                        </div>
                    })}


                    {/* {media?.map((item, i) => {
                        return <Col key={`med-${i}`}>
                            {item.tittle}
                            <LorettaContentMin item={item} />
                        </Col>
                    })} */}
                </Row>


            </Col>
        </Row>
    </div>
}

export default Media;