import { useEffect } from "react";
import { Container } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchData, objDeleted } from "../slices/lorettaSlice";
import { commonConstants } from "../../util/constants/common.constants";
import { Button } from "reactstrap";
import { iconArrowUpSquareFill } from "../../util/forms.util";
import { formatDateDDMMYYYHHMM } from "../../util/common.util";
// import { formConstants } from "../../../util/constants/form.constans";
// import { ltButtonBar } from "../components.loretta";
import logo from '../../util/content/img/logo.png'

const DetailNew = (props) => {

    const defineParms = (props) => {
        const { id, coll, idForm } = props;

        const locSeacrh = new URLSearchParams(location.search);

        const coll_ = locSeacrh.get('coll');
        const id_ = locSeacrh.get('id');
        const idForm_ = locSeacrh.get('idForm');

        var coll__ = coll ? coll : coll_;
        var id__ = id ? id : id_;
        var idForm__ = coll ? idForm : idForm_;

        return { id: id__, coll: coll__, idForm: idForm__ }
    }

    const location = useLocation();
    const { id, coll, idForm } = defineParms(props, location);


    const { obj } = useSelector((state) => state.loretta);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!obj) {
            dispatch(fetchData({ coll, id }));
        }
    }, [])

    const getLink = (file) => {
        let style={
            display: 'inline-block',
            backgroundColor: '#f44336',
            color: '#FFFFFF',
            padding: '14px 25px',
            textAlign: 'center',
            textDecoration: 'none',
            fontSize: '16px',
            marginLeft: '20 px',
            opacity: 0.9
        }

        return <p style={{textAlign: 'center'}} key={file.fileSrc}>
            <a className="btn" href={file.fileSrc} target="_blank" style={style}>
            <span className="btn-inner--icon">
                {iconArrowUpSquareFill(20)} VER {file.fileName}
            </span>
            </a>
        </p>
    }

    const mailtmp = ({ tittle, subtittle, desc, files, fileSrc }) => {
        // let logo_ = 'https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/logo.png?alt=media&token=42fa3fcc-d5a7-4f53-9343-733e9d3e4352'
        // let createdAt = obj.createdAt ? formatDateDDMMYYYHHMM(new Date(obj.createdAt.seconds * 1000 + obj.createdAt.nanoseconds / 1000000)) : null;
        let createdAt = obj.createdAt || '';

        const html = `        
        
        ${files?.map((el) => {
            return getLink(el);
        })
            }        

        `;

        return <>
            <h2>{`Fecha:  ${createdAt}`}</h2>
            <h1 style={{ color: '#5e9ca0' }} ><strong><img src={logo} style={{ margin: '0 auto', display: 'block', width: '50%', height: '50%' }} /></strong></h1>
            <h1 style={{ color: 'rgb(94, 156, 160)', textAlign: 'center' }} >{tittle}</h1>
            <h2 style={{ color: 'rgb(46, 108, 128)', textAlign: 'center' }} >{subtittle}</h2>
            <p style={{ textAlign: 'center' }}
            ><span style={{ textAlign: 'start', color: 'rgb(106, 113, 140)', backgroundColor: 'rgb(255, 255, 255)', fontSize: '18px' }} >{desc}</span></p>

            ${
                files?.map((el) => {
                    return getLink(el);
                })
            }

            <div style={{background:'#eee',border:'1px solid #ccc', padding:'5px 10px'}}>
                <span style={{color: 'rgb(34, 34, 34)', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 'small'}}>Mensaje enviado por Congroup Portal Empresarial,</span>
                <br style={{color: 'rgb(34, 34, 34)', fontFamily: 'Arial, Helvetica, sans-serif', fontSize:'small'}}/>
                <span style={{color: 'rgb(34, 34, 34)', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 'small'}}>Administraci&oacute;n https://loretta3-congroup.web.app/</span></div>
        </>;
    }

    



    return <>
        {
            obj?.id ? <>
                {mailtmp(obj)}
                {/* <pre>{JSON.stringify(obj, null, 10)}</pre> */}
            </> :
                <>
                    {commonConstants.MSG_EMPTY_DATA_}
                </>
        }
    </>
}

export default DetailNew;