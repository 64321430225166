import { useDispatch, useSelector } from "react-redux";
import { fetchArticles, fetchBanner } from "../../slices/lorettaSlice";
import { useEffect } from "react";
import LorettaLoading from "../../../util/components/LorettaLoading";
import { Button } from "reactstrap";
import { commonConstants } from "../../../util/constants/common.constants";
import { Link } from "react-router-dom";

const ListBanner = () => {
  const dispatch = useDispatch();

  const { banners, loading } = useSelector((state) => state.loretta);


  useEffect(() => {
    if (!banners) dispatch(fetchBanner())
  }, [])

  return (
    <div className="container">
      {/* {loading && <LorettaLoading />} */}
      <div className="row">
        <h1>Banner</h1>
      </div>
      <div className="row action-bar">
        <div className="col-12">
          <Button
            onClick={() => dispatch(fetchBanner())}
            className="button-primary"
          >
            <span className="btn-inner--icon">
              <i className="fa fa-rotate-right" />&nbsp;
            </span>
            {commonConstants.LBL_UPALL}
          </Button>

          <Button disabled
            onClick={() => window.location = "/new-article"}
            className="button-primary"
          >
            <span className="btn-inner--icon">
              <i className="fa fa-plus" />&nbsp;
            </span>
            {commonConstants.LBL_NEW}
          </Button>

          {/* <Link to="/add-new">
            <button className="button-primary">Nuevo</button>
          </Link> */}
        </div>
      </div>
      <div className="row">
        <table className="lorettaTable">
          <thead>
            <tr>
              {/* <th>ID</th> */}
              <th>Título</th>
              <th>Subtitulo</th>
              <th>Image</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {banners &&
              banners.map((item, i) => (
                <tr key={i}>
                  {/* <td>{item.id}</td> */}
                  <td>{item.altText}</td>
                  <td>{item.caption}</td>
                  {/* <td>{item.contentType}</td> */}
                  <td><Link to={item.src} target="_blank">
                        {item.src}
                  </Link></td>
                  <td>
                    <div className="col-12">
                      {/* <Button onClick={() => handleDelete(item.id)} title="Ocultar" >
                          <span className="btn-inner--icon">
                            <i className="fa fa-eye-slash" />
                          </span>
                        </Button> */}
                      <Button disabled
                        onClick={() => window.location = `/edit-article?id=${item.id}`}
                        className="button-primary" title="Editar" >
                        <span className="btn-inner--icon">
                          <i className="fa fa-pencil" />
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListBanner;