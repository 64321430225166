import { useDispatch, useSelector } from "react-redux";
import './css/services.css';
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { fetchServices } from "../slices/lorettaSlice";
import { Card, CardBody, CardSubtitle, CardTitle, CardText, CardLink, CardImg, CardFooter, CardHeader } from "reactstrap";
import { iconFill } from "../../util/forms.util";


const Articles = () => {
	// const {services} = props;
	const dispatch = useDispatch();
	const { articles } = useSelector((state) => state.loretta);

	let icon =

		useEffect(() => {
			if (!articles) dispatch(fetchServices())
		}, [])

	const getControl = (item) => {
		return  <div className="card">
		<img className="card-img-top" src={item.imageSrc}/>
		<div className="card-body">
		<a href={item.link} target="_blank"><h5 className="card-title">{item.tittle}</h5></a>
		  <p className="card-text">{item.subtittle}</p>
		  
		</div>
	  </div>
		
		// <Card
		// 	className="my-2"
		// >
		// 	<div className="div-img">
		// 		<Link to={item.link} target="_blank">
		// 			<CardImg
		// 				alt="Imagen tomada de "
		// 				src={item.imageSrc}
		// 			// width={100}
		// 			/>
		// 		</Link>
		// 	</div>
		// 	<CardHeader>
		// 		<CardTitle>
		// 			<Link to={item.link} target="_blank">
		// 				<b> {item.tittle}</b>
		// 			</Link>
		// 		</CardTitle>
		// 		<CardSubtitle>
		// 			{item.subtittle}
		// 		</CardSubtitle>
		// 	</CardHeader>
		// 	<CardBody>
		// 		<CardText className="one-line-txt">
		// 			{item.desc}
		// 		</CardText>
		// 		{/* <LorettaPopUp item={item}></LorettaPopUp> */}
		// 	</CardBody>
		// </Card>
	}

	return <div className="div-articles">
		<h2>Últimas noticias</h2>
		<div className="row">
			{articles?.map((item, i) => {
				return <div className="col-3" key={`divart${i}`}>{getControl(item)}</div>
			})}
		</div>
	</div >
}

export default Articles;