import { Link, useNavigate } from "react-router-dom";
import { login, stsChange } from "../slices/lorettaSlice"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert, Navbar, NavbarBrand
} from "reactstrap";
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux";
import logo from "../../util/content/img/logo-min.png";

const Login = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { register, formState: { errors }, handleSubmit } = useForm();



  // if (logged) {
  //console.log(user);
  //   navigate(commonConstants.URL_HOME);
  // }

  const onSubmit = (data, e) => {
    //console.log(data);
    e.target.reset();
    dispatch(login({ ...data }));
  }

  const handleCloseClick = () => {
    dispatch(stsChange({ name: "error", value: null }))
  }



  return <>

    <>
      <main >
        {/* {loading && <LorettaLoading />} */}
        <section className="section section-shaped section-lg">
          <div className="shape shape-style-1 bg-gradient-default">

          </div>
          <Container className="pt-lg-7">
            <Row className="justify-content-center">
              <Col lg="5">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-white pb-1">


                    <Navbar
                      className="navbar-main navbar-transparent navbar-light headroom"
                      expand="lg"
                      id="navbar-main"
                    >

                      <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                        <img
                          alt="..."
                          src={logo}
                          width={100}
                        />
                      </NavbarBrand>

                      <div className="text-muted text-center mb-3">
                        <h1>
                        Portal Empresarial
                        </h1>
                      </div>
                    </Navbar>

                  </CardHeader>
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      {/* <small>Iniciar Sesión</small> */}
                      <h3>Iniciar Sesión</h3>
                    </div>

                    {/* <form onSubmit={handleSubmit(onSubmit)}>
                      <input {...register("email", { required: true })} />
                      <input type="password" {...register("password", { pattern: '\\S+@\\S+\\.\\S+' })} />
                      <input type="submit" />
                    </form> */}



                    <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                          {/* <Input
                            {...register("email", { required: true })}
                            placeholder="Email"
                            name='email'
                            type="email" />
                             */}
                              <input {...register("email", { required: true })} 
                              placeholder="Email"
                              name='email'
                              type="email"
                              className="form-control"
                              />
                        </InputGroup>
                        {errors["email"] &&
                          <div className="requirements">
                            <span role="alert">{`${errors["email"].type} | ${errors["email"].message}`}</span>
                          </div>
                        }

                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                          <input type="password" {...register("password", { pattern: '\\S+@\\S+\\.\\S+' })} placeholder="Password"
                            name='password'  className="form-control"/>
                          {/* <Input type="password" {...register("password", { pattern: '\\S+@\\S+\\.\\S+' })}
                            placeholder="Password"
                            name='password'
                            autoComplete="off"
                          /> */}
                        </InputGroup>
                        {errors["password"] &&
                          <div className="requirements">
                            <span role="alert">{`${errors["password"].type} | ${errors["password"].message}`}</span>
                          </div>
                        }
                        
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4 btn btn-info"
                          color="danger"
                          type="submit"
                        >
                          Acceder
                        </Button>
                      
                      </div>


                    </Form>

                    {/* <Form role="form">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                            <Input 
                            // onChange={this.handleChange}
                              placeholder="Email"
                              name='email'
                              type="email" />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                            <Input 
                            // onChange={this.handleChange}
                              placeholder="Password"
                              name='password'
                              type="password"
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>

                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor=" customCheckLogin"
                          >
                            <span>Recordarme</span>
                          </label>
                        </div>
                        <div className="text-center">
                          <Button
                            className="my-4 btn btn-danger"
                            color="primary"
                            type="button"
                            // onClick={this.handleSubmit}
                          >
                            Iniciar Sesión
                          </Button>                          
                        </div>
                      </Form> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          
        </section>      
      </main>
      {/* <SimpleFooter /> */}

    </>

  </>
}

export default Login