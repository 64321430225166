var pattern = /\S+@\S+\.\S+/;
var strPattern = pattern.source;

export const commonConstants = {

    ON: 'ON',
    OFF: 'OFF',

    /**
     * URLs
     */

    URL_LOGIN: 'login',//'login',
    URL_HOME: '*',
    URL_PAINTER: 'painter',
    URL_DASHBOARD: 'dashboard',
    URL_TEST: 'test',

    URL_NEWS: 'portal-news',
    URL_NEWS_ADD: 'portal-news-add',
    URL_ARTICLES: 'portal-articles',
    URL_MEDIA: 'portal-media',
    URL_SERVICES: 'portal-services',
    URL_SERVICES_ADD: 'portal-services-add',
    URL_SERVICES_EDIT: 'portal-services-edit',
    URL_BANNER: 'portal-banner',
    

    URL_DATA_SHOW: 'portal-data-shw',
    URL_DATA_LIST: 'portal-data-lst',
    URL_DATA_ADD: 'portal-data-add',

    URL_PDF: 'loretta3-pdf',



    /**
    * Button Action Label
    */
    LBL_NEW: 'Nuevo',
    LBL_UPD: 'Editar',
    LBL_DEL: 'Eliminar',
    LBL_SAV: 'Guardar',
    LBL_ONOFF: 'On/Off',
    LBL_LIST: 'Ver todos',
    LBL_UPALL: 'Actualizar',
    LBL_SEND: 'Enviar',

    /**
     * Collections DB
     */


    /**
     * Collection Object
     */

    SESSION: 'session',
    COLL_CONTENT: 'content',    
    COLL_LANDING: 'landing',
    COLL_CUSTOMERS: 'customers',
    COLL_SERVICES: 'services',
    COLL_ARTICLES: 'article',
    MAIL_TO: 'mailTo',
    LORETTA: 'loretta',
    


    QUERY_LIMIT_10: 10,
    QUERY_LIMIT_20: 20,
    QUERY_LIMIT_50: 50,

    /**
     * Messages
     */
    MSG_ERROR: '¡Algo esta mal! Si el error persiste comuniquese con el administrador',
    MSG_ERROR_TITTLE: 'Algo está mal',
    MSG_ERROR_MSG: 'Si el error persiste comuniquese con el administrador',   
    MSG_ERROR_MISSING: 'Falta información',
    MSG_EMPTY: '',
    MSG_LOADING_DATA: '... Cargando datos',
    MSG_EMPTY_DATA: 'No hay coincidencias',
    MSG_EMPTY_DATA_: 'No se registran datos',
    ADD_MSG_SUCCESS: 'Registro Exitoso',
    ADD_MSG_ERROR: 'No se guardó el registro',

    TYPE_DATETIME: "datetime",
    TYPE_RADIO: "radio",
    TYPE_CHECK: "check",
    TYPE_TEXT: "text",
    TYPE_NUMBER: "number",
    TYPE_SELECT: "select",

    HOME: 'home',
    LOGIN: 'login',
    SERVICES: 'services',
    MEDIA: 'media',

    MENU: [
        {
            label: 'INICIO',
            nav: 'home',
            type: 'link'
        },
        // {
        //     label: 'SERVICIOS',
        //     nav: 'services',
        //     type: 'link'
        // },
        // {
        //     label: 'MEDIOS',
        //     nav: 'media',
        //     type: 'link'
        // },
        {
            label: 'CONTACTENOS',
            nav: 'landing',
            type: 'link'
        },
    ],

    FORM_LANDING: [
        {
            collection: 'landing',
            controlId: 'fullname',
            type: 'text',
            labelES: '¿De dónde nos escribes?',
            labelEN: 'Full Name',
            placeholder: 'Nombre, compañía',
            htmlControl: 'input',
            required: true,
            requiredMsg: 'Campo requerido',
            maxLength: 200,
            maxLengthMsg: 'No más de 200 caracteres',
            minLength: 5,
            minLengthMsg: 'Mínimo 5 caracteres',
            active: true,
            order: 10,
        },

        {
            collection: 'landing',
            controlId: 'phone',
            type: 'number',
            labelES: 'Teléfono',
            labelEN: 'Phone',
            placeholder: 'Móvil, fijo',
            htmlControl: 'input',
            required: true,
            requiredMsg: 'Campo requerido',
            maxLength: 200,
            maxLengthMsg: 'No más de 200 caracteres',
            minLength: 2,
            minLengthMsg: 'Mínimo 2 caracteres',
            active: true,
            order: 30,
        },

        {
            collection: 'landing',
            controlId: 'email',
            type: 'email',
            labelES: 'e-mail',
            labelEN: 'Email',
            placeholder: 'Corporativo, personal',
            htmlControl: 'input',
            required: true,
            requiredMsg: 'Campo requerido',
            pattern: strPattern,
            patternMsg: 'Formato de correo inválido',
            maxLength: 200,
            maxLengthMsg: 'No más de 200 caracteres',
            minLength: 2,
            minLengthMsg: 'Mínimo 2 caracteres',
            active: true,
            order: 40,
        },
        {
            collection: 'landing',
            controlId: 'message',
            type: 'textarea',
            labelES: '¿En que te podemos colaborar?',
            labelEN: 'Full Name',
            placeholder: 'Mensaje',
            htmlControl: 'textarea',
            required: true,
            requiredMsg: 'Campo requerido',
            maxLength: 500,
            maxLengthMsg: 'No más de 500 caracteres',
            minLength: 5,
            minLengthMsg: 'Mínimo 5 caracteres',
            active: true,
            order: 10,
        },
    ],
};
