import contentConstants from "../util/constants/content.constants";

export const lttHandleError = (error) => {
    switch (error.name) {
        case 'FirebaseError':
            if(error.code = 'unavailable'){
                // let payload = {error: {...error}, infoLoretta: 'OFFLINE'}
                // return payload;// throw error_;
            }          

            if(error.code = 'auth/network-request-failed'){
            
            }            

            throw error;
        default:
            throw error;
    }
}

export const lttHandleError_ = (error) => {
    switch (error.name) {
        case 'FirebaseError':
            // if(error.code = 'unavailable'){
            //     let payload = {error: {...error}, infoLoretta: 'OFFLINE'}
            //     return payload;// throw error_;
            // }            
            throw error;
        default:
            throw error;
    }
}

export const lttInitOffline = () => {
    let banners = contentConstants.BANNERS;
    let articles = contentConstants.ARTICLES;
    let mediaAndNews = contentConstants.MEDIA;
    let media = contentConstants.MEDIA;
    let news  = contentConstants.NEWS;
    let services = contentConstants.SERVICES;
    
    return { banners, articles, mediaAndNews, media, news, services}
}