import React from "react";
import ReactLoading from 'react-loading';
// import * as Utils from '../../util/components/charts/util';


class LorettaLoading extends React.Component {

    

    render(){
        // return <div className="div-loading-full">
        //     <ReactLoading type="bubbles" color={'rgb(53, 126, 221, 0.8)'} height={'100%'} width={'100%'} />
        // </div>

        return <div className="div-loading-full ">
        <ReactLoading type="spin" color={'rgb(236,9,40, 0.8)'} className="div-loading-child"/>
    </div>
    }
}
export default LorettaLoading;