import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row, Form, FormGroup, FormText, Input, Label, Button, Badge } from "reactstrap";
import { checkMail, init, initForm, objAdded, sendMail, storageFile, storageImage, stsChange } from "../../slices/lorettaSlice";
import { initialContent, initialCustomer, initialForm, metaMail } from "../../../util/forms.util";
import { commonConstants } from "../../../util/constants/common.constants";
import { formConstants } from "../../../util/constants/form.constans";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import LorettaFilesUp from "../../../util/components/LorettaFilesUp";

const Send = (props) => {

    const defineParms = (props) => {
        const { id, coll, idForm } = props;

        const locSeacrh = new URLSearchParams(location.search);

        const coll_ = locSeacrh.get('coll');
        const id_ = locSeacrh.get('id');
        const idForm_ = locSeacrh.get('idForm');

        var coll__ = coll ? coll : coll_;
        var id__ = id ? id : id_;
        var idForm__ = coll ? idForm : idForm_;

        return { id: id__, coll: coll__, idForm: idForm__ }
    }

    const location = useLocation();
    const { id, coll, idForm } = defineParms(props, location);

    const { files, toMail } = useSelector((state) => state.loretta);
    const form = initialForm(idForm);
    const [form_, setForm_] = useState(form);

    const dispatch = useDispatch();


    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        dispatch(initForm({ idForm }));
    }, [])

    const onSubmit = (data, e) => {

        const defaultObj = initialContent({ contentType: idForm });

        const payload = {
            ...defaultObj,
            ...data,
            files,
        }

        dispatch(objAdded({coll, obj:payload}));

        const mail = metaMail(payload);
        const mailsSelected = toMail?.filter((obj) => obj.selected === true);
        mail.bcc = mailsSelected.map((el) => el.email);

        if(window.confirm(`Esta Seguro de enviar el correo a ${mail.to} y copia oculta a ${mail.bcc}`)){
            dispatch(sendMail(mail));
        }
    
        e.target.reset();
        dispatch(stsChange({name: "files", value: []}))       

    }

    const getFields = () => {
        let fields = Object.entries(form_?.fields)
            .map(([key, value]) => value);
        return fields;
    }

    const getControl = ({ controlId, labelES, placeholder, type }) => {

        var control = <>;</>

        switch (type) {
            case 'files':
                control = <LorettaFilesUp idForm={idForm} key={`files${controlId}`} />
                break;

            default:
                control = <FormGroup key={`group${controlId}`}>
                    <Label htmlFor={controlId}>
                        {labelES}
                    </Label>
                    <input className="form-control"
                        id={controlId}
                        name={controlId}
                        placeholder={placeholder || ''}
                        type={type}
                        autoComplete="false"
                        {...register(controlId)}
                    />
                </FormGroup>

                break;
        }



        return control;
    }

    const handleClick = (e, id, selected) =>{
        // console.log(toMail);
        dispatch(checkMail({id, selected}))
      }

    const mailsTo = () => {
        const mailsSelected = toMail?.filter((obj) => obj.selected === true);
        return <><br/>
        <h6>Se enviará el boletin por correo electrónico a los siguientes clientes: {mailsSelected?.length}</h6>
        {mailsSelected?.map((el) => {
          return <Badge color="primary" pill >{el.email}
           <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" 
           onClick={(e) => {handleClick(e, el.id, false)}}
        >
                <span aria-hidden="true">&times;</span>
            </button>
          </Badge>
        })}
        {mailsSelected?.length == 0 && <p>No hay correos seleccionados</p>}
        </>
      }

    return <Container>
        {/* {buttonBar(`../${commonConstants.URL_DATA_LIST}/?coll=${coll}&list=true&idForm=${idForm}`)} */}

        {
            form_ &&
            <>
                <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                    {getFields().map((field) => {
                        return getControl(field);
                    })}

                    {/* <form id="uploadForm" enctype="multipart/form-data">
                                    <input type="file" id="fileInput" name="files[]" multiple accept="image/*,video/*"/>
                                    <button type="button" id="uploadButton">Upload Files</button>
                                </form> */}


{mailsTo()}
                    <Button className="btn-danger btAdd">
                        {form_.btNew}
                    </Button>
                </Form>
            </>

        }


    </Container>
}

export default Send;