import { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader, CardText, Col, Container, Row } from "reactstrap";
import LorettaBanner from "../../util/components/LorettaBanner";
import { useDispatch, useSelector } from "react-redux";
import { commonConstants } from "../../util/constants/common.constants";
// import { map } from "jquery";
import { fetchAll, objAdded, fetchBanner, fetchArticles, fetchServices } from "../slices/lorettaSlice";
import { Link } from "react-router-dom";
import LorettaLoading from "../../util/components/LorettaLoading";
import { formConstants } from "../../util/constants/form.constans";
import { Button } from "react-bootstrap";
import Test from "../Test";

const Index = () => {


    return <Container>
 {/* <Test></Test> */}
        <Row xs="1" md="2" lg="2">
            <Card>
                <CardHeader>
                    Datos de la compañía
                </CardHeader>
                <CardBody>
                    <Row xs="1" md="1" lg="2">

                        <Col className='colButton'>
                            <Link to={`${commonConstants.URL_DATA_SHOW}/?coll=company&id=congroup&idForm=company`} className="btn btn-icon btn-lg d-block w-100 btn-large ">
                                <Row>
                                    <Col className="col-2">
                                        <span className="btn-inner--icon mr-1">
                                            <i className="ni ni-chart-bar-32" />
                                        </span><br />
                                    </Col>
                                    <Col className="colButtonTxt">
                                        <span className="btn-inner--text">Congroup</span>
                                    </Col>
                                </Row>
                            </Link>
                        </Col>

                        <Col className='colButton'>
                            <Link to={`${commonConstants.URL_DATA_LIST}/?coll=${commonConstants.COLL_CUSTOMERS}&list=true&idForm=${formConstants.CUSTOMER}`} className="btn btn-icon btn-lg d-block w-100 btn-large ">
                                <Row>
                                    <Col className="col-2">
                                        <span className="btn-inner--icon mr-1">
                                            <i className="ni ni-chart-bar-32" />
                                        </span><br />
                                    </Col>
                                    <Col className="colButtonTxt">
                                        <span className="btn-inner--text">Clientes</span>
                                    </Col>
                                </Row>
                            </Link>
                        </Col>


                        <Col className='colButton'>
                            <Link to={`${commonConstants.URL_DASHBOARD}`} className="btn btn-icon disabled-link  btn-lg d-block w-100">
                                <Row>
                                    <Col className="col-2">
                                        <span className="btn-inner--icon mr-1">
                                            <i className="ni ni-chart-bar-32" />
                                        </span><br />
                                    </Col>
                                    <Col className="colButtonTxt">
                                        <span className="btn-inner--text">Dashboard</span>
                                    </Col>
                                </Row>
                            </Link>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Card>
                <CardHeader>
                    Contenidos
                </CardHeader>
                <CardBody>
                    <Card body>
                        <Row xs="1" md="1" lg="2">
                            <Col className='colButton'>
                                <Link to={`${commonConstants.URL_DATA_LIST}/?coll=${commonConstants.COLL_CONTENT}&list=true&idForm=${formConstants.NEWS}`} className="btn btn-icon btn-lg d-block w-100 btn-large ">
                                    <Row>
                                        <Col className="col-2">
                                            <span className="btn-inner--icon mr-1">
                                                <i className="ni ni-fat-add" />
                                            </span><br />
                                        </Col>
                                        <Col className="colButtonTxt">
                                            <span className="btn-inner--text">Boletines</span>
                                        </Col>
                                    </Row>
                                </Link>
                            </Col>

                            <Col className='colButton'>
                            <Link to={`${commonConstants.URL_DATA_LIST}/?coll=${commonConstants.COLL_CONTENT}&list=true&idForm=${formConstants.ARTICLE}`} className="btn btn-icon btn-lg d-block w-100 btn-large ">
                                    <Row>
                                        <Col className="col-2">
                                            <span className="btn-inner--icon mr-1">
                                                <i className="ni ni-fat-delete" />
                                            </span><br />
                                        </Col>
                                        <Col className="colButtonTxt">
                                            <span className="btn-inner--text">Artículos</span>
                                        </Col>
                                    </Row>
                                </Link>
                            </Col>

                            <Col className='colButton'>
                            <Link to={`${commonConstants.URL_DATA_LIST}/?coll=${commonConstants.COLL_CONTENT}&list=true&idForm=${formConstants.MEDIA}`} className="btn btn-icon btn-lg d-block w-100 btn-large ">
                                    <Row>
                                        <Col className="col-2">
                                            <span className="btn-inner--icon mr-1">
                                                <i className="ni ni-bullet-list-67" />
                                            </span><br />
                                        </Col><Col className="colButtonTxt">
                                            <span className="btn-inner--text">Medios</span>
                                        </Col>
                                    </Row>
                                </Link>
                            </Col>

                            <Col className='colButton'>
                                <Link to={`${commonConstants.URL_DATA_LIST}/?coll=${commonConstants.COLL_CONTENT}&list=true&idForm=${formConstants.SERVICE}`} className="btn btn-icon btn-lg d-block w-100 btn-large ">
                                    <Row>
                                        <Col className="col-2">
                                            <span className="btn-inner--icon mr-1">
                                                <i className="ni ni-bullet-list-67" />
                                            </span><br />
                                        </Col><Col className="colButtonTxt">
                                            <span className="btn-inner--text">Servicios</span>
                                        </Col>
                                    </Row>
                                </Link>
                            </Col>

                            <Col className='colButton'>
                            <Link to={`${commonConstants.URL_DATA_LIST}/?coll=${commonConstants.COLL_CONTENT}&list=true&idForm=${formConstants.BANNER}`} className="btn btn-icon btn-lg d-block w-100 btn-large ">
                                    <Row>
                                        <Col className="col-2">
                                            <span className="btn-inner--icon mr-1">
                                                <i className="ni ni-bullet-list-67" />
                                            </span><br />
                                        </Col><Col className="colButtonTxt">
                                            <span className="btn-inner--text">Banner</span>
                                        </Col>
                                    </Row>
                                </Link>
                            </Col>
                        </Row>
                    </Card>
                </CardBody>
            </Card>
        </Row>
    </Container>

}

export default Index;