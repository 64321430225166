// import { appendErrors } from "react-hook-form";

// import { useState } from "react";

const LttInputForm = (props) => {
  const { readOnly, field, register, error } = props;

  const getControl = () => {
    {
      switch (field.htmlControl) {
        case "input":

          return (
            <>
              <input id={`${register.name}`} type={field.type} aria-invalid={error ? "true" : "false"} {...register}
                key={`${field.controlId}`}
                placeholder={`${field.placeholder}`}
                readOnly={readOnly}
                autoComplete="false"
                onChange={(event) => {
                  event.target.value = field.type === "email" ? event.target.value.toLowerCase() : event.target.value.toUpperCase();
                  if (field.controlId === "plate" && event.target.value.length >= 2) {
                    field.type = "number"
                  }

                }}
              />
              <div className="underline"></div>
              <label htmlFor={`${register.name}`}>{`${field.labelES}`}</label>
            </>
          )
        // break;
        case "textarea":

          return (
            <>
              {/* <input id={`${register.name}`} type={field.type} aria-invalid={error ? "true" : "false"} {...register}
                key={`${field.controlId}`}
                placeholder={`${field.placeholder}`}
                readOnly={readOnly}
                autoComplete="false"
                onChange={(event) => {
                  event.target.value = field.type==="email" ? event.target.value.toLowerCase() : event.target.value.toUpperCase();
                  if(field.controlId === "plate" && event.target.value.length >= 2) {
                    field.type = "number"
                  }
                  
                }}
              /> */}
              <textarea id={`${register.name}`} name={`${register.name}`} rows="10" cols="50"
                aria-invalid={error ? "true" : "false"} {...register}
                key={`${field.controlId}`}
                placeholder={`${field.placeholder}`}
                readOnly={readOnly}
                autoComplete="false"
                onChange={(event) => {
                  event.target.value = field.type === "email" ? event.target.value.toLowerCase() : event.target.value.toUpperCase();
                  if (field.controlId === "plate" && event.target.value.length >= 2) {
                    field.type = "number"
                  }

                }}
              ></textarea>
              <div className="underline"></div>
              <label htmlFor={`${register.name}`}>{`${field.labelES}`}</label>
            </>
          )
        // break;
        case "select":
          return (<>
            <select {...register} className="form-select" value={field.defaultValue} >
              {field.options && field.options.map((opt, i) => {
                return <option value={opt.value} key={`${i}-${opt.value}`}>{`${opt.label}`}</option>
              })}

            </select>
            <div className="underline"></div>
            <label className="label-select" htmlFor={`${register.name}`}>{`${field.labelES}`}</label>
          </>)

        default:
          return <p >{`${field.labelES}`}</p>
        // break;
      }
    }
  }

  return <>
    {field && register && (
      <>
        <div className="form-row">
          <div className="input-data">
            {getControl()}
          </div>
          {error && (//&& errors.type === "required" && (
            <div className="requirements">
              <span role="alert">{`${error.type} | ${error.message}`}</span>
            </div>
          )}
        </div>
      </>)}
  </>
}

export default LttInputForm;