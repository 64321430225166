export const contentConstants = {
    COMPANY: {
        "phones": [
            {
                "label": "+576017429261",
                // "id": "+576017429261"
            },
            {
                "label": "3158574605  ",
                // "id": "3158574605"
            },
            {
                // "id": "3174039626",
                "label": "3174039626"
            },
            {
                // "id": " 3158668895",
                "label": " 3158668895"
            }
        ],
        "whatsapp": "573153592552",
        "addresses": [
            "Cra. 15 #88-21 of 702 Bogotá, Colombia"
        ],
        "emails": [
            "comunicaciones@congroupsas.com",
            "congroupweb@gmail.com",
            "congroup@gmail.com"
        ],
        // "id": "congroup"
    },

    SERVICES: [
        {
            // // "createdAt": "28-Feb-2024 9:43",
            // "videoSrc": "",
            "tittle": "Asesoría administrativa y financiera",
            // // "endAt": null,
            // "fileName": "",
            // "subTitle": "",
            // "createdBy": "admin",
            // "subtittle": "",
            "desc": "Guiar a la personas que puedan conseguir los objetivos planteados. Orientar a los clientes hacia una decisión financiera rentable y segura.",
            "contentType": "service",
            // "active": true,
            // "fileSrc": "",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/service%2F240228943-asesoria.png?alt=media&token=403bed7a-1cf1-4daa-8df5-bfb6154233bc",
            // // "startAt": "28-Feb-2024 9:43",
            // // "id": "2dC30mBhhjMfbo0cXJrK"
        },
        {
            "contentType": "service",
            // "subTitle": "",
            // // "createdAt": "28-Feb-2024 9:37",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/service%2F240228937-infografia%20capacitacion.pdf?alt=media&token=c5bfd32e-09f7-4807-a642-72f482402ff6",
            "tittle": "Capacitación",
            // "active": true,
            // // "startAt": "28-Feb-2024 9:37",
            "desc": "Las entidades de economia solidaria son verdaderas empresas que requieren de resultados.La debida y adecuada formacion proveniente de selectos expertos en el tema es el primer paso para asegurar organizaciones solidarias fuertes, lideres y sostenible",
            // "videoSrc": "",
            // "fileSrc": "",
            // // "endAt": null,
            // "subtittle": "",
            // "createdBy": "admin",
            // "fileName": "",
            // // "id": "qkpJLIosJnPDRoBigLx5"
        },
        {
            // // "startAt": "28-Feb-2024 9:36",
            // "subTitle": "",
            "tittle": "Servicio jurídico",
            // // "endAt": null,
            "contentType": "service",
            // "videoSrc": "",
            // "active": true,
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/service%2F240228936-infografia%20servicio%20juridico.pdf?alt=media&token=08bdecc6-a240-4937-9496-c80c36da369a",
            "desc": "La mejor asesoría para personas jurídicas y naturales",
            // "fileName": "",
            // "subtittle": "",
            // // "createdAt": "28-Feb-2024 9:36",
            // "fileSrc": "",
            // "createdBy": "admin",
            // // "id": "slWKDpKZmZsSylJf38m8"
        },
        {
            // // "createdAt": "28-Feb-2024 9:35",
            // "videoSrc": "",
            "desc": "Disminuya sus costos laborales. Manejamos su contabilidad dentro y fuera de su entidad",
            // // "endAt": null,
            // "subtittle": "",
            // "active": true,
            "createdBy": "admin",
            "tittle": "Outsourcing contable y asesoría tributaria",
            // "fileSrc": "",
            // // "startAt": "28-Feb-2024 9:35",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/service%2F240228935-infografia%20contabilidad.pdf?alt=media&token=444d643d-939d-497e-ab41-2f141d417b4c",
            // "fileName": "",
            "contentType": "service",
            // "subTitle": "",
            // // "id": "oGCW8VXHDHeUMNcT2MRf"
        },
        {
            // "subTitle": "",
            // "createdBy": "admin",
            // "videoSrc": "",
            "desc": "Permitanos indicarle como tener todas las actividades y operaciones",
            // "subtittle": "",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/service%2F240228935-infografia%20Revisoria.pdf?alt=media&token=380a0d79-f59c-4447-95fc-ad6d2512f1d6",
            // // "createdAt": "28-Feb-2024 9:35",
            "tittle": " Auditoria y revisoría fiscal",
            // "fileName": "",
            // "fileSrc": "",
            // // "endAt": null,
            // "active": true,
            // // "startAt": "28-Feb-2024 9:35",
            "contentType": "service",
            // // "id": "9JiefLSQQd4XWZNg4wsi"
        }
    ],

    MEDIA: [

        {
            // // "createdAt": "26-Feb-2024 14:55",
            // "subTitle": "",
            // // "startAt": "26-Feb-2024 14:55",
            // "active": true,
            "contentType": "media",
            "tittle": "Calendario tributario 2024",
            "fileName": "Calendario tributario 2024",
            // "createdBy": "admin",
            // "imageSrc": "",
            // "subtittle": "calendario",
            // "desc": "",
            // // "endAt": "26-Feb-2024 14:55",
            // "videoSrc": "",
            "fileSrc": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/contentType%2F2402261455-CALENDARIO%20TRIBUTARIO%202024%20CONGROUP%20CONSULTORES%20SAS%20(1).pdf?alt=media&token=e38187ea-13b0-4ec8-9c4b-84f24f7a632d",
            // // "id": "z1WwBoWqQc9URRwcJK5q"
        },
        {
            // // "createdAt": "21-Dec-2023 21:59",
            "fileName": "Manual de Seguridad",
            // // "startAt": "21-Dec-2023 21:59",
            "contentType": "media",
            // "videoSrc": "",
            "fileSrc": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/manual_seguridad.pdf?alt=media&token=d673c428-ae3b-4868-9038-2ac9a15cd689",
            // "active": true,
            // "imageSrc": "",
            // "link": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/manual_seguridad.pdf?alt=media&token=d673c428-ae3b-4868-9038-2ac9a15cd689",
            // "description": "",
            // "createdBy": "admin",
            // "subtittle": "Congroup",
            "tittle": "Manual de seguridad",
            // // "endAt": "30-Dec-2023 19:0",
            // // "id": "N6Gx4ic2Q49kfGUO8bzN"
        },
        {
            // // "startAt": "21-Dec-2023 21:59",
            // "description": "",
            // "subtittle": "2023",
            // "link": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/Calendario%20Congroup%202023.pdf?alt=media&token=0f544f13-8f88-4ea5-8c8a-49d89afc786c",
            // "imageSrc": "",
            "fileSrc": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/Calendario%20Congroup%202023.pdf?alt=media&token=0f544f13-8f88-4ea5-8c8a-49d89afc786c",
            // "createdBy": "admin",
            // // "endAt": "30-Dec-2023 19:0",
            "fileName": "Calendario Congroup 2023",
            // "contentType": "media",
            // "active": true,
            // "videoSrc": "",
            // // "createdAt": "21-Dec-2023 21:59",
            "tittle": "Calendario Tributario",
            // // "id": "7p9vFn5RKgfmOsGvXXdL"
        }
    ],

    NEWS: [
        {
            // "imageSrc": "",
            // "active": true,
            // // "createdAt": "29-Feb-2024 10:40",
            // "videoSrc": "",
            "contentType": "newsletter",
            // "createdBy": "admin",
            "tittle": "Boletin 01",
            "files": [
                {
                    "fileName": "Boletín 01, tabla de retención y otros datos informativos",
                    "fileSrc": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/newsletter%2F2402291040-Bolet%C3%ADn%2001%2C%20tabla%20de%20retenci%C3%B3n%20y%20otros%20datos%20informativos?alt=media&token=d80ba1f2-f445-4cd2-ba13-d13b0edfe57a"
                },
                {
                    "fileName": "20240202_circular_externa_55_0 tasa de contribución supersolidaria ",
                    "fileSrc": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/newsletter%2F2402291040-20240202_circular_externa_55_0%20tasa%20de%20contribuci%C3%B3n%20supersolidaria%20?alt=media&token=86fd33a8-9915-4b95-b973-7fc33b484e64"
                }
            ],
            "fileSrc": "",
            // // "endAt": "29-Feb-2024 10:40",
            // // "startAt": "29-Feb-2024 10:40",
            "subtittle": "Boletín 01, tabla de retención y otros datos informativos",
            // "desc": "",
            // "fileName": "",
            // // "id": "JWvV4X4lwCbVcz800Uoj"
        },
       
      
      
       
        {
            // // "createdAt": "21-Feb-2024 17:2",
            // // "endAt": "21-Feb-2024 17:2",
            // "desc": "",
            "contentType": "newsletter",
            // // "startAt": "21-Feb-2024 17:2",
            // "active": true,
            "files": [
                {
                    "fileName": "Cobro tasa de contribución año 2024",
                    "fileSrc": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/news%2F240221172-20240202_circular_externa_55_0%20tasa%20de%20contribuci%C3%B3n%20supersolidaria%20(2).pdf?alt=media&token=80a1f0d0-a056-4b31-a91f-293f8f7563b8"
                },
            ],
            "tittle": "CIRCULAR EXTERNA No. 55",
            // "imageSrc": "",
            "subtittle": "COBRO TASA DE CONTRIBUCIÓN AÑO 2024",
            // "videoSrc": "",
            // "subTitle": "",
            // "fileName": "",
            // "createdBy": "admin",
            // // "id": "NXioB3V8bnd9qzug4ask"
        },
        {
            "subtittle": "tabla de retención y otros datos informativos",
            // "imageSrc": "",
            // // "endAt": "21-Feb-2024 16:46",
            // "fileName": "",
            // "videoSrc": "",
            // // "startAt": "21-Feb-2024 16:46",
            "files": [
                {
                    "fileName": "Boletin 01",
                    "fileSrc": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/news%2F2402211646-Bolet%C3%ADn%2001%2C%20tabla%20de%20retenci%C3%B3n%20y%20otros%20datos%20informativos.pdf?alt=media&token=8a8370c3-197f-4da5-b920-d4af71f848bd"
                },
            ],
            // "active": true,
            // "desc": "",
            "tittle": "Boletin 01",
            // "createdBy": "admin",
            "contentType": "newsletter",
            // // "createdAt": "21-Feb-2024 16:46",
            // // "id": "l7JVQqybkZjallVh3k7J"
        },
        {
            // "imageSrc": "",
            "tittle": "Boletin 01",
            "contentType": "newsletter",
            // "createdBy": "admin",
            // // "startAt": "20-Feb-2024 16:22",
            "files": [
                {
                    "fileName": "Tabla de retención y otros datos informativos",
                    "fileSrc": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/news%2F2402201622-Bolet%C3%ADn%2001%2C%20tabla%20de%20retenci%C3%B3n%20y%20otros%20datos%20informativos.pdf?alt=media&token=c087308a-283d-4037-a91d-a34af58fd417"
                },
            ],
            // "active": true,
            "subtittle": "Tabla de retención y otros datos informativos",
            // "desc": "tabla de retención y otros datos informativos",
            // "subTitle": "",
            // "fileName": "",
            // "videoSrc": "",
            // // "endAt": "20-Feb-2024 16:22",
            // // "createdAt": "20-Feb-2024 16:22",
            // // "id": "4Z0ElbUOB3HIhaX9HiYO"
        },

    ],

    ARTICLES: [
        {
            // // "startAt": "26-Feb-2024 14:34",
            // "fileSrc": "",
            "tittle": "Colombia está en el grupo de los mejores países para pensionarse este 2024",
            "link": "https://www.portafolio.co/mis-finanzas/jubilacion/cuales-son-los-mejores-paises-para-disfrutar-de-la-pension-en-2024-598841",
            // "videoSrc": "",
            // // "endAt": "26-Feb-2024 14:34",
            // "active": true,
            "subtittle": " Índice Global de Jubilación",
            // "fileName": "",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/article%2F2402261434-6584cbe3a8578.jpeg?alt=media&token=74853caf-2517-4494-979e-1ab4340a750a",
            // // "createdAt": "26-Feb-2024 14:34",
            // "createdBy": "admin",
            // "desc": "",
            "contentType": "article",
            // // "id": "9dp9VnBV99no3PXNyGAy"
        },
        {
            "link": "https://www.eltiempo.com/mas-contenido/cuatro-lideres-extraordinarios-que-transforman-los-territorios-851788",
            // "desc": "",
            "contentType": "article",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/article%2F2402261419-65d5559796a2a.jpeg?alt=media&token=a2ce6f52-2a85-4259-ac5c-e73ab79bda69",
            // // "endAt": "26-Feb-2024 14:19",
            // // "startAt": "26-Feb-2024 14:19",
            // "createdBy": "admin",
            "tittle": "La EIA destaca el rol de la academia en la economía de Colombia",
            // "active": true,
            // "fileSrc": "",
            // // "createdAt": "26-Feb-2024 14:19",
            "subtittle": "Lo hacen a través de la cultura, la generación de empleo y la protección al medioambiente.",
            // "fileName": "",
            // "videoSrc": "",
            // // "id": "Ly1Y9dM0lrnKecblMUjO"
        },
        {
            // "fileName": "",
            "imageSrc": "https://firebasestorage.googleapis.com/v0/b/etechne-congroup.appspot.com/o/article%2F2402261256-649c5a3289e87.r_1708732447840.0-0-3000-1801.jpeg?alt=media&token=dd406424-e2b0-435a-9abe-992241cce8ab",
            // // "endAt": "26-Feb-2024 12:56",
            // "subTitle": "",
            // "createdBy": "admin",
            // "active": true,
            // // "createdAt": "26-Feb-2024 12:56",
            "link": "https://www.eltiempo.com/economia/empresas/grupo-argos-cerro-el-2023-con-unas-ganancias-consolidadas-de-1-5-billones-858121",
            // "fileSrc": "",
            "tittle": "Grupo Argos cerró el 2023 con unas ganancias consolidadas de $ 1,5 billones",
            "subtittle": "En la Asamblea de Accionistas propondrá la entrega de un dividendo por acción de 636 pesos.",
            // "videoSrc": "",
            // // "startAt": "26-Feb-2024 12:56",
            "contentType": "article",
            // // "id": "qCenXHyB0epXpsXgAhAA"
        },
        {
            // "active": true,
            // // "startAt": "21-Dec-2023 21:59",
            "tittle": "Facturación electrónica: ¿cómo evitar sanciones de la Dian?, le contamos",
            // // "createdAt": "21-Dec-2023 21:59",
            // "fileSrc": "",
            "link": "https://www.eltiempo.com/economia/finanzas-personales/facturacion-electronica-como-evitar-sanciones-de-la-dian-828354",
            // "createdBy": "admin",
            // // "endAt": "30-Dec-2023 19:0",
            // "videoSrc": "",
            // "description": "",
            "subtittle": "La facturación electrónica es un requisito indispensable para las empresas en Colombia.",
            "contentType": "article",
            "imageSrc": "https://www.eltiempo.com/files/article_main_1200/uploads/2020/07/06/5f02d914365fc.jpeg",
            // // "id": "WUFTDRsigjYnai4ViKfR"
        },
        {
            "imageSrc": "https://img.lalr.co/cms/2023/12/19175528/Eco_HistoricoUVT_WEB.jpg?size=sm",
            // "createdBy": "admin",
            // "description": "",
            "subtittle": "La Dian actualizó el monto de esta unidad para el próximo año. Esta medición se emplea para definir valores de impuestos a favor",
            "contentType": "article",
            "link": "https://www.larepublica.co/economia/que-es-la-unidad-de-valor-tributario-3770261",
            // // "endAt": "30-Dec-2023 19:0",
            // "active": true,
            // // "startAt": "21-Dec-2023 21:59",
            // // "createdAt": "21-Dec-2023 21:59",
            // "videoSrc": "",
            "tittle": "Conozca para qué funciona la Unidad de Valor Tributario y a cuánto sube en 2024",
            // "fileSrc": "",
            // // "id": "Pl1WsXOLG3CXkarBQ5KO"
        },
        {
            "link": "https://www.eltiempo.com/economia/finanzas-personales/como-sera-la-retencion-de-fuente-de-la-dian-y-a-quienes-aplica-en-el-2024-834330",
            // "createdBy": "admin",
            // // "createdAt": "21-Dec-2023 21:59",
            "tittle": "¿Cómo será la retención de fuente de la Dian y a quiénes aplica en el 2024?",
            // "fileSrc": "",
            // // "endAt": "30-Dec-2023 19:0",
            "contentType": "article",
            "imageSrc": "https://www.eltiempo.com/files/article_main_1200/uploads/2023/06/14/648a04ae10271.jpeg",
            // // "startAt": "21-Dec-2023 21:59",
            // "videoSrc": "",
            // "description": "",
            // "active": true,
            "subtittle": "Le contamos algunos datos que debe saber para el pago de esta obligación en el próximo año. ",
            // // "id": "BrhXlENFRtb0c2Kc3RH2"
        }
    ],

    BANNERS: [
        {
            // // "startAt": {
            //     "seconds": 1703213945,
            //     "nanoseconds": 416000000
            // },
            "subtittle": "¿Todos los riegos de su empresa están cubiertos?",
            // "active": true,
            // "link": "",
            // "description": "",
            // // "createdAt": {
            //     "seconds": 1703213945,
            //     "nanoseconds": 416000000
            // },
            // "videoSrc": "",
            "tittle": "Riesgos",
            // "fileSrc": "",
            // "createdBy": "admin",
            "imageSrc": "https://picsum.photos/id/42/1200/400",
            // // "endAt": {
            //     "seconds": 1703980800,
            //     "nanoseconds": 0
            // },
            "contentType": "banner",
            // // "key": "th8OzeH4u5pG8iL1B4yl",
            // // "id": "th8OzeH4u5pG8iL1B4yl"
        },
        {
            // // "createdAt": {
            //     "seconds": 1703213945,
            //     "nanoseconds": 416000000
            // },
            // "active": true,
            "subtittle": "Consulte con nosotros",
            // "fileSrc": "",
            "contentType": "banner",
            "tittle": "¿Conoce si tiene demandas?",
            // "description": "",
            "imageSrc": "https://picsum.photos/id/2/1200/400",
            // "link": "",
            // "videoSrc": "",
            // // "endAt": {
            //     "seconds": 1703980800,
            //     "nanoseconds": 0
            // },
            // // "startAt": {
            //     "seconds": 1703213945,
            //     "nanoseconds": 416000000
            // },
            // // "key": "febBm6aqdsOThtz04Ifd",
            // // "id": "febBm6aqdsOThtz04Ifd"
        },
        {
            // "fileSrc": "",
            // // "startAt": {
            //     "seconds": 1703213945,
            //     "nanoseconds": 416000000
            // },
            "imageSrc": "https://picsum.photos/id/180/1200/400",
            // "videoSrc": "",
            // "active": true,
            "tittle": "Capacitaciones",
            // // "createdAt": {
            //     "seconds": 1703213945,
            //     "nanoseconds": 416000000
            // },
            // "description": "",
            // "link": "",
            "contentType": "banner",
            "subtittle": "Para directivos y empleados",
            // // "endAt": {
            //     "seconds": 1703980800,
            //     "nanoseconds": 0
            // },
            // // "key": "dqPtTwwZnd0QtVfu0bEy",
            // // "id": "dqPtTwwZnd0QtVfu0bEy"
        },
        {
            // "fileSrc": "",
            // "videoSrc": "",
            // // "endAt": {
            //     "seconds": 1703980800,
            //     "nanoseconds": 0
            // },
            "subtittle": "¿Requiere una asesoría jurídica, auditoría o servicio contable?",
            // "link": "",
            // // "startAt": {
            //     "seconds": 1703213945,
            //     "nanoseconds": 416000000
            // },
            // "description": "",
            "tittle": "Servicios jurídicos, auditoría, economía",
            // "createdBy": "admin",
            // // "createdAt": {
            //     "seconds": 1703213945,
            //     "nanoseconds": 416000000
            // },
            // "active": true,
            "imageSrc": "https://picsum.photos/id/48/1200/400",
            // "contentType": "banner",
            // // "key": "UHWhJlZ5FNYWb9S6o6vj",
            // // "id": "UHWhJlZ5FNYWb9S6o6vj"
        },
        {
            "tittle": "Impuestos",
            // "fileSrc": "",
            // "active": true,
            // "description": "",
            "imageSrc": "https://picsum.photos/id/60/1200/400",
            // "videoSrc": "",
            // "createdBy": "admin",
            "subtittle": "¿Tiene dudas acerca de los impuestos que debe pagar?\t",
            // "link": "",
            // // "startAt": {
            //     "seconds": 1703213945,
            //     "nanoseconds": 416000000
            // },
            // // "endAt": {
            //     "seconds": 1703980800,
            //     "nanoseconds": 0
            // },
            // // "createdAt": {
            //     "seconds": 1703213945,
            //     "nanoseconds": 416000000
            // },
            "contentType": "banner",
            // // "key": "9qzPHt5tl4y7YLscUJ7A",
            // // "id": "9qzPHt5tl4y7YLscUJ7A"
        }
    ],

    CUSTOMERS: [
        {
            "active": true,
            "email": "deonis82@hotmail.com",
            "phone": "",
            "createdBy": "admin",
            // "createdAt": "21-Feb-2024 16:13",
            "idDoc": "52842270",
            "fullname": "DEONIS MARCELA URRITIA MOSQUERA",
            // "id": "zjehSnGbEpHRZmMlQsdt",
            // "key": "zjehSnGbEpHRZmMlQsdt",
            // "startAt": null,
            // "endAt": null
        },
        {
            "active": true,
            "idDoc": "19220484",
            "fullname": "JESUS ERNESTO SUAREZ BRAVO-TERMOESTUCHES",
            "phone": "3750752",
            "createdBy": "admin",
            // "createdAt": "21-Feb-2024 16:13",
            "email": "termoestuches@hotmail.com",
            // "id": "Loh8FydKsZ8TnT0RpXb6",
            // "key": "Loh8FydKsZ8TnT0RpXb6",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "secretariaamecri@hotmail.com",
            "active": true,
            "createdBy": "admin",
            "fullname": "LUZ  HELENA BASTOS",
            "idDoc": "63308853",
            // "createdAt": "21-Feb-2024 16:12",
            "phone": "",
            // "id": "lIGS1msanoBJXFk40fn0",
            // "key": "lIGS1msanoBJXFk40fn0",
            // "startAt": null,
            // "endAt": null
        },
        {
            "idDoc": "900421392",
            "active": true,
            "email": "contabilidad@corpecol.com",
            // "createdAt": "21-Feb-2024 16:11",
            "phone": "4324242",
            "createdBy": "admin",
            "fullname": "PROYECTOS CORPECOL S.A.S.",
            // "id": "y7Sb0QWApOP3TCj9NJUG",
            // "key": "y7Sb0QWApOP3TCj9NJUG",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "pbautista@grupounipharm.com",
            "idDoc": "830068691",
            "fullname": "GRUPO UNIPHARM S A Y/O LABORATORIOS UNI S A",
            "phone": "4102050",
            "createdBy": "admin",
            "active": true,
            // "createdAt": "21-Feb-2024 16:11",
            // "id": "REtw0np1Y79u1LqIlYFj",
            // "key": "REtw0np1Y79u1LqIlYFj",
            // "startAt": null,
            // "endAt": null
        },
        {
            "idDoc": "830035448",
            "email": "m_baezp@hotmail.com",
            "active": true,
            "phone": "6039605",
            "fullname": "FONDO DE EMPLEADOS DE CEMEX COLOMBIA",
            "createdBy": "admin",
            // "createdAt": "21-Feb-2024 16:11",
            // "id": "GskdO2Ae1EDFcCJLHVl8",
            // "key": "GskdO2Ae1EDFcCJLHVl8",
            // "startAt": null,
            // "endAt": null
        },
        {
            "fullname": "FONDO DE EMPLEADOS DE CEMEX COLOMBIA",
            "createdBy": "admin",
            // "createdAt": "21-Feb-2024 16:11",
            "active": true,
            "email": "gerenciafinancierayadministrativa@fecem.com",
            "phone": "6039605",
            "idDoc": "830035448",
            // "id": "SxvNNwTL20u9UxQ45jqL",
            // "key": "SxvNNwTL20u9UxQ45jqL",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "fedeuco.gerencia@uco.edu.co",
            "createdBy": "admin",
            "idDoc": "811010755",
            "active": true,
            "phone": "6343291",
            // "createdAt": "21-Feb-2024 16:10",
            "fullname": "FONDO DE EMPLEADOS DE LA UNVERSIDAD CATOLICA DEL ORIENTE \"FEDEUCO\"",
            // "id": "qpMhqKg4gSwMHMyrTNWF",
            // "key": "qpMhqKg4gSwMHMyrTNWF",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "liliana.quique.gomez1@merck.com",
            "createdBy": "admin",
            "phone": "3156495865",
            // "createdAt": "21-Feb-2024 16:10",
            "idDoc": "860022308",
            "active": true,
            "fullname": "FONDO DE EMPLEADOS DE MERCK SHARP & DOHME COLOMBIA FONDOFROSST",
            // "id": "NXPMVE7PBY6TXSvmyMJU",
            // "key": "NXPMVE7PBY6TXSvmyMJU",
            // "startAt": null,
            // "endAt": null
        },
        {
            "active": true,
            "idDoc": "79543592",
            "phone": "",
            "fullname": "JUAN HARVEY MORA",
            "email": "jharvey73@hotmail.com",
            // "createdAt": "21-Feb-2024 16:10",
            "createdBy": "admin",
            // "id": "IivauhDKAbARm3hXw0zj",
            // "key": "IivauhDKAbARm3hXw0zj",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "estuchesyempaques@hotmail.com",
            "createdBy": "admin",
            "fullname": "ESTUCHES Y EMPAQUES LTDA",
            "idDoc": "900197249",
            "active": true,
            "phone": "2509485",
            // "createdAt": "21-Feb-2024 16:8",
            // "id": "JmYK5Ef5jsQEMMOaGPsh",
            // "key": "JmYK5Ef5jsQEMMOaGPsh",
            // "startAt": null,
            // "endAt": null
        },
        {
            "idDoc": "830028961",
            "email": "joseale528@gmail.com",
            // "createdAt": "21-Feb-2024 16:8",
            "active": true,
            "createdBy": "admin",
            "phone": "",
            "fullname": "FONDO DE EMPLEADOS DE QUALA",
            // "id": "q9Ua0ksa3HIhy9zRENbw",
            // "key": "q9Ua0ksa3HIhy9zRENbw",
            // "startAt": null,
            // "endAt": null
        },
        {
            "fullname": "FONDO DE EMPLEADOS DE QUALA",
            "createdBy": "admin",
            "email": "Jesanchez@quala.com.co",
            "active": true,
            "idDoc": "830028961",
            // "createdAt": "21-Feb-2024 16:8",
            "phone": "7701000",
            // "id": "xm1zSJQngYWsz24TW4aI",
            // "key": "xm1zSJQngYWsz24TW4aI",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "gisaza@corantioquia.gov.co",
            "idDoc": "",
            "active": true,
            "phone": "",
            // "createdAt": "21-Feb-2024 16:7",
            "fullname": "",
            "createdBy": "admin",
            // "id": "7p9vEMNLA8kiwdL6IZbf",
            // "key": "7p9vEMNLA8kiwdL6IZbf",
            // "startAt": null,
            // "endAt": null
        },
        {
            "phone": "",
            // "createdAt": "21-Feb-2024 16:7",
            "idDoc": "",
            "email": "juan.mendez@santareyes.com.co",
            "fullname": "",
            "active": true,
            "createdBy": "admin",
            // "id": "3NlhsCDbNBLeK3gppHoP",
            // "key": "3NlhsCDbNBLeK3gppHoP",
            // "startAt": null,
            // "endAt": null
        },
        {
            "createdBy": "admin",
            "idDoc": "",
            // "createdAt": "21-Feb-2024 16:7",
            "fullname": "",
            "email": "gerencia@feunimilitar.com",
            "active": true,
            "phone": "",
            // "id": "mRm4cqE9NFjoQ1GyJbHT",
            // "key": "mRm4cqE9NFjoQ1GyJbHT",
            // "startAt": null,
            // "endAt": null
        },
        {
            "phone": "6051535",
            "idDoc": "901107546",
            // "createdAt": "21-Feb-2024 16:7",
            "email": "gjgomezc@ucn.edu.co",
            "createdBy": "admin",
            "fullname": "FONDO DE EMPLEADOS UNIVERSIDAD CATOLICA DEL NORTE UCN",
            "active": true,
            // "id": "CpxKFFVBGS1LFkZ53pNH",
            // "key": "CpxKFFVBGS1LFkZ53pNH",
            // "startAt": null,
            // "endAt": null
        },
        {
            "fullname": "",
            "idDoc": "",
            // "createdAt": "21-Feb-2024 16:6",
            "active": true,
            "createdBy": "admin",
            "email": "azharescontratos@gmail.com",
            "phone": "",
            // "id": "4fNmMmpA9vxzV0s09I6k",
            // "key": "4fNmMmpA9vxzV0s09I6k",
            // "startAt": null,
            // "endAt": null
        },
        {
            "active": true,
            "phone": "",
            // "createdAt": "21-Feb-2024 16:5",
            "email": "jhoncontador13@gmail.com",
            "idDoc": "",
            "createdBy": "admin",
            "fullname": "",
            // "id": "YmLiNM3Lt2nJvLQdedAU",
            // "key": "YmLiNM3Lt2nJvLQdedAU",
            // "startAt": null,
            // "endAt": null
        },
        {
            // "createdAt": "21-Feb-2024 16:5",
            "email": "empleadosmsd_fondo@merck.com",
            "active": true,
            "phone": "3156495865",
            "idDoc": "860022308",
            "createdBy": "admin",
            "fullname": "FONDO DE EMPLEADOS DE MERCK SHARP & DOHME COLOMBIA FONDOFROSST",
            // "id": "1kOot75Ul1STPyhCub2Q",
            // "key": "1kOot75Ul1STPyhCub2Q",
            // "startAt": null,
            // "endAt": null
        },
        {
            "createdBy": "admin",
            "idDoc": "860006632",
            "phone": "",
            "email": "fondofin@co.ibm.com",
            // "createdAt": "21-Feb-2024 16:5",
            "fullname": "FONDO DE EMPLEADOS DE IBM DE COLOMBIA",
            "active": true,
            // "id": "4GgHOq3dTx9El2tqdnmT",
            // "key": "4GgHOq3dTx9El2tqdnmT",
            // "startAt": null,
            // "endAt": null
        },
        {
            // "createdAt": "21-Feb-2024 16:4",
            "fullname": "FONDO DE EMPLEADOS DE AW FABER -CASTELL TECNACRIL",
            "idDoc": "830142724",
            "active": true,
            "createdBy": "admin",
            "email": "fondo.faber@faber-castell.com.co",
            "phone": "4204084",
            // "id": "LmDvytif5pP0s2y3lEFn",
            // "key": "LmDvytif5pP0s2y3lEFn",
            // "startAt": null,
            // "endAt": null
        },
        {
            "active": true,
            "createdBy": "admin",
            "email": "fondehosmil@yahoo.com",
            "fullname": "FONDO DE EMPLEADOS DEL HOSPITAL MILITAR CENTRAL Y DEL SUBSISTEMA DE SALUD DE LAS FUERZAS MILITARES",
            "idDoc": "800067217",
            "phone": "2877003",
            // "createdAt": "21-Feb-2024 16:4",
            // "id": "pLIaDQ0gqhLMDSXOTegE",
            // "key": "pLIaDQ0gqhLMDSXOTegE",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "fondo.empleados@cencosud.com.co",
            // "createdAt": "21-Feb-2024 16:4",
            "createdBy": "admin",
            "idDoc": "860509471",
            "active": true,
            "phone": "8258481",
            "fullname": "FONDO DE EMPLEADOS FLORES DE FUNZA LTDA",
            // "id": "7W9n2ghGiwI8ncYgC6Gd",
            // "key": "7W9n2ghGiwI8ncYgC6Gd",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "fzagerente@femza.com.co",
            "createdBy": "admin",
            "idDoc": "860509471",
            // "createdAt": "21-Feb-2024 16:3",
            "active": true,
            "phone": "8258481",
            "fullname": "FONDO DE EMPLEADOS FLORES DE FUNZA LTDA",
            // "id": "yIDcCYrxZtS24Mr9ye8J",
            // "key": "yIDcCYrxZtS24Mr9ye8J",
            // "startAt": null,
            // "endAt": null
        },
        {
            "active": true,
            "email": "federico.eckardt@heritagegroup.com.co",
            "createdBy": "admin",
            "fullname": "HERITAGE GROUP SAS",
            "phone": "",
            // "createdAt": "21-Feb-2024 16:3",
            "idDoc": "900269166",
            // "id": "989dXW5HjUJ75WiBQBld",
            // "key": "989dXW5HjUJ75WiBQBld",
            // "startAt": null,
            // "endAt": null
        },
        {
            // "createdAt": "21-Feb-2024 16:3",
            "createdBy": "admin",
            "active": true,
            "phone": "6447690",
            "idDoc": "800012577",
            "fullname": "FONDO DE EMPLEADOS FARMA DE COLOMBIA \"FONDOFARMA\"",
            "email": "fondo.empleados@farmadecolombia.com.co",
            // "id": "hcBSOn3vccSp2c2RIrlN",
            // "key": "hcBSOn3vccSp2c2RIrlN",
            // "startAt": null,
            // "endAt": null
        },
        {
            "active": true,
            "idDoc": "52380620",
            "phone": "",
            "createdBy": "admin",
            "email": "dimaesja@hotmail.com",
            "fullname": "CLAUDIA PATRICIA SUAREZ",
            // "createdAt": "21-Feb-2024 16:2",
            // "id": "aQ8cuz8DvCsQtJ4pjvpV",
            // "key": "aQ8cuz8DvCsQtJ4pjvpV",
            // "startAt": null,
            // "endAt": null
        },
        {
            "fullname": "CLAUDIA PATRICIA SUAREZ",
            "createdBy": "admin",
            // "createdAt": "21-Feb-2024 16:2",
            "idDoc": "52380620",
            "phone": "3174039628",
            "active": true,
            "email": "corpecol@ecopetrol.com.co",
            // "id": "cdaDh6qjSQnzf8MvMwLG",
            // "key": "cdaDh6qjSQnzf8MvMwLG",
            // "startAt": null,
            // "endAt": null
        },
        {
            "idDoc": "52380620",
            // "createdAt": "21-Feb-2024 16:2",
            "createdBy": "admin",
            "fullname": "CLAUDIA PATRICIA SUAREZ",
            "email": "cpatriciasuarez@yahoo.com.mx",
            "active": true,
            "phone": "3174039628",
            // "id": "U4VR2gdF95AGKbrtkHJN",
            // "key": "U4VR2gdF95AGKbrtkHJN",
            // "startAt": null,
            // "endAt": null
        },
        {
            "createdBy": "admin",
            "active": true,
            "phone": "",
            // "createdAt": "21-Feb-2024 16:0",
            "idDoc": "63504127",
            "fullname": "ADRIANA YANETH SALAZAR HERNANDEZ",
            "email": "angela_valeria_jorge@hotmail.com",
            // "id": "SJflc1gIyif4eFI1La70",
            // "key": "SJflc1gIyif4eFI1La70",
            // "startAt": null,
            // "endAt": null
        },
        {
            "fullname": "ADRIANA YANETH SALAZAR HERNANDEZ",
            "createdBy": "admin",
            "active": true,
            "email": "amolina@spsm.com.co",
            "phone": "",
            // "createdAt": "21-Feb-2024 15:59",
            "idDoc": "63504127",
            // "id": "4N8f9xMS0v94TT1opXvW",
            // "key": "4N8f9xMS0v94TT1opXvW",
            // "startAt": null,
            // "endAt": null
        },
        {
            "createdBy": "admin",
            "idDoc": "63504127",
            "phone": "3158574605",
            "email": "ADRIANASALAZA90@hotmail.com",
            "fullname": "ADRIANA YANETH SALAZAR HERNANDEZ",
            // "createdAt": "21-Feb-2024 15:59",
            "active": true,
            // "id": "HnEixmYvq5VeFLrTJsDF",
            // "key": "HnEixmYvq5VeFLrTJsDF",
            // "startAt": null,
            // "endAt": null
        },
        {
            "phone": "",
            "active": true,
            // "createdAt": "21-Feb-2024 15:59",
            "createdBy": "admin",
            "idDoc": "860077728",
            "fullname": "FONDO DE EMPLEADOS DE SCHLUMBERGER SURENCO FEDESCO",
            "email": "fedesco@slb.com",
            // "id": "Ja4tVeLsYwgSRlANYpKX",
            // "key": "Ja4tVeLsYwgSRlANYpKX",
            // "startAt": null,
            // "endAt": null
        },
        {
            "active": true,
            "fullname": "JUAN FRANCISCO ZABALA",
            // "createdAt": "21-Feb-2024 15:58",
            "phone": "3172317962",
            "idDoc": "79403191",
            "email": "zabala.juan@congroupsas.com",
            "createdBy": "admin",
            // "id": "Byqe84g73JEcXlJ0gT6a",
            // "key": "Byqe84g73JEcXlJ0gT6a",
            // "startAt": null,
            // "endAt": null
        },
        {
            "fullname": "FONDO DE EMPLEADOS BAYER(FEBAY)",
            "active": true,
            "createdBy": "admin",
            "email": "gerencia@febay.co",
            "idDoc": "860040746",
            "phone": "7469804",
            // "createdAt": "21-Feb-2024 15:58",
            // "id": "G3XcSavFt3wJOn1sdube",
            // "key": "G3XcSavFt3wJOn1sdube",
            // "startAt": null,
            // "endAt": null
        },
        {
            "phone": "",
            "createdBy": "admin",
            "idDoc": "901113929",
            "active": true,
            // "createdAt": "21-Feb-2024 15:58",
            "email": "lliliana.quique@fondomsd.com.co",
            "fullname": "QUALCANN S.A.S",
            // "id": "ReO91Zid63UAjgX77EwF",
            // "key": "ReO91Zid63UAjgX77EwF",
            // "startAt": null,
            // "endAt": null
        },
        {
            "phone": "",
            "active": true,
            "email": "gerenciafondefem@gmail.com",
            "idDoc": "901113929",
            // "createdAt": "21-Feb-2024 15:57",
            "createdBy": "admin",
            "fullname": "QUALCANN S.A.S",
            // "id": "DPc0UV05h7Gv7a0ti5bQ",
            // "key": "DPc0UV05h7Gv7a0ti5bQ",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "e.covo@qualcann.co",
            "active": true,
            "phone": "3204121599",
            "fullname": "QUALCANN S.A.S",
            // "createdAt": "21-Feb-2024 15:57",
            "createdBy": "admin",
            "idDoc": "901113929",
            // "id": "mYBJilcOEKZvUFjzUQuU",
            // "key": "mYBJilcOEKZvUFjzUQuU",
            // "startAt": null,
            // "endAt": null
        },
        {
            "idDoc": "901113929",
            // "createdAt": "21-Feb-2024 15:57",
            "createdBy": "admin",
            "fullname": "QUALCANN S.A.S",
            "phone": "",
            "email": "carlos.sepulveda@santareyes.com.co",
            "active": true,
            // "id": "fuoMtaPueDXISuRqpcId",
            // "key": "fuoMtaPueDXISuRqpcId",
            // "startAt": null,
            // "endAt": null
        },
        {
            "createdBy": "admin",
            // "createdAt": "21-Feb-2024 15:57",
            "fullname": "QUALCANN S.A.S",
            "phone": "3204121599",
            "idDoc": "901113929",
            "active": true,
            "email": "contabilidad@qualcann.co",
            // "id": "JnkVy4KDibIIywu8xIKN",
            // "key": "JnkVy4KDibIIywu8xIKN",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "fecem@fecem.com",
            "createdBy": "admin",
            "active": true,
            "fullname": "PROYECTOS INNOVADORES DE FECEM SAS",
            "idDoc": "900476098",
            // "createdAt": "21-Feb-2024 15:56",
            "phone": "6039605",
            // "id": "SnN5MotsIsHKUmFfYraw",
            // "key": "SnN5MotsIsHKUmFfYraw",
            // "startAt": null,
            // "endAt": null
        },
        {
            "phone": "4545458",
            "createdBy": "admin",
            "active": true,
            "fullname": "JAEN SAS",
            "email": "info@jaen.com.co",
            // "createdAt": "21-Feb-2024 15:56",
            "idDoc": "901512250",
            // "id": "SEWWcgcnD7efLMFQ2ac2",
            // "key": "SEWWcgcnD7efLMFQ2ac2",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "gerencia@azhares.com",
            "active": true,
            "fullname": "INVERSIONES GARCIA VEZGA SAS",
            "createdBy": "admin",
            "idDoc": "900655621",
            "phone": "7597777",
            // "createdAt": "21-Feb-2024 15:56",
            // "id": "nBMQLLIkBJOYqbZR910k",
            // "key": "nBMQLLIkBJOYqbZR910k",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "gerentewell@independence.com.co",
            "phone": "5875333",
            "active": true,
            // "createdAt": "21-Feb-2024 15:55",
            "createdBy": "admin",
            "idDoc": "900558943",
            "fullname": "INDEPENDENCE WELL FONDO DE EMPLEADOS Y/O INDEPENDENCE WELL",
            // "id": "fUjh51dCPwIRL4XdBEYE",
            // "key": "fUjh51dCPwIRL4XdBEYE",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "fdiaz@grupounipharm.com",
            "createdBy": "admin",
            // "createdAt": "21-Feb-2024 15:55",
            "phone": "4102050",
            "active": true,
            "idDoc": "830068691",
            "fullname": "GRUPO UNIPHARM S A Y/O LABORATORIOS UNI S A",
            // "id": "nc9LFJvpthQ4TGkHweP0",
            // "key": "nc9LFJvpthQ4TGkHweP0",
            // "startAt": null,
            // "endAt": null
        },
        {
            // "createdAt": "21-Feb-2024 15:55",
            "phone": "4254460",
            "fullname": "FONDO DE EMPLEADOS VINOS Y LICORES",
            "active": true,
            "email": "fondomecq@domecq.com.co",
            "idDoc": "860014545",
            "createdBy": "admin",
            // "id": "xKuF1exbvPBeDiX8JQvg",
            // "key": "xKuF1exbvPBeDiX8JQvg",
            // "startAt": null,
            // "endAt": null
        },
        {
            "fullname": "FONDO DE EMPLEADOS UNIVERSIDAD CATOLICA DEL NORTE UCN",
            "phone": "6051535",
            // "createdAt": "21-Feb-2024 15:54",
            "active": true,
            "createdBy": "admin",
            "idDoc": "901107546",
            "email": "yusugaz@ucn.edu.co",
            // "id": "xWznZOwIqKXmT6x8gxDR",
            // "key": "xWznZOwIqKXmT6x8gxDR",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "fzaasistente@femza.com.co",
            "createdBy": "admin",
            // "createdAt": "21-Feb-2024 15:54",
            "idDoc": "860509471",
            "fullname": "FONDO DE EMPLEADOS FLORES DE FUNZA LTDA",
            "phone": "8258481",
            "active": true,
            // "id": "dqpEk9006MJSIp7IbueR",
            // "key": "dqpEk9006MJSIp7IbueR",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "fondo.empleados@ext-farma.com",
            "phone": "6447690",
            "active": true,
            "createdBy": "admin",
            "fullname": "FONDO DE EMPLEADOS FARMA DE COLOMBIA \"FONDOFARMA\"",
            "idDoc": "800012577",
            // "createdAt": "21-Feb-2024 15:54",
            // "id": "sXTJKR7QyN1k6BRaVoCg",
            // "key": "sXTJKR7QyN1k6BRaVoCg",
            // "startAt": null,
            // "endAt": null
        },
        {
            "idDoc": "860048061",
            "phone": "3203397",
            "active": true,
            "fullname": "FONDO DE EMPLEADOS DEL INSTITUTO DE DESARROLLO URBANO",
            "email": "fosol1@idu.gov.co",
            "createdBy": "admin",
            // "createdAt": "21-Feb-2024 15:53",
            // "id": "IS6Awk9zJmtV5AO8CNJh",
            // "key": "IS6Awk9zJmtV5AO8CNJh",
            // "startAt": null,
            // "endAt": null
        },
        {
            "active": true,
            // "createdAt": "21-Feb-2024 15:53",
            "fullname": "FONDO DE EMPLEADOS DEL INSTITUTO COLOMBIANO DE NORMAS TECNICAS ICONTEC",
            "createdBy": "admin",
            "idDoc": "860534184",
            "phone": "6078888",
            "email": "pmalaver@icontec.org",
            // "id": "7Zf82fsMgTJD2dTl3E3c",
            // "key": "7Zf82fsMgTJD2dTl3E3c",
            // "startAt": null,
            // "endAt": null
        },
        {
            "createdBy": "admin",
            "email": "fondoempleados@husi.org.co",
            // "createdAt": "21-Feb-2024 15:50",
            "fullname": "FONDO DE EMPLEADOS DEL HOSPITAL UNIVERSITARIO SAN IGNACIO (FONDEHUSI)",
            "phone": "5946161",
            "idDoc": "900368637",
            "active": true,
            // "id": "1oTgBqkdoJfvgXXhcr3C",
            // "key": "1oTgBqkdoJfvgXXhcr3C",
            // "startAt": null,
            // "endAt": null
        },
        {
            "phone": "5946161",
            "createdBy": "admin",
            "idDoc": "900368637",
            "email": "fondoempleados@husi.org.co",
            "fullname": "FONDO DE EMPLEADOS DEL HOSPITAL UNIVERSITARIO SAN IGNACIO (FONDEHUSI)",
            // "createdAt": "21-Feb-2024 15:49",
            "active": true,
            // "id": "lTgdKa6d9UMAuFXSF8zr",
            // "key": "lTgdKa6d9UMAuFXSF8zr",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "contabilidad@fondehosmil.com",
            "createdBy": "admin",
            "idDoc": "800067217",
            "active": true,
            // "createdAt": "21-Feb-2024 15:49",
            "fullname": "FONDO DE EMPLEADOS DEL HOSPITAL MILITAR CENTRAL Y DEL SUBSISTEMA DE SALUD DE LAS FUERZAS MILITARES",
            "phone": "2877003",
            // "id": "WnCBA2qnWGseOAzTBz30",
            // "key": "WnCBA2qnWGseOAzTBz30",
            // "startAt": null,
            // "endAt": null
        },
        {
            "fullname": "FONDO DE EMPLEADOS DEL GIMNASIO FEMENINO(FONDEFEM)",
            "phone": "6578437",
            "active": true,
            // "createdAt": "21-Feb-2024 15:47",
            "email": "admonfondefem@gmail.com",
            "createdBy": "admin",
            "idDoc": "830043269",
            // "id": "jNeGtPFrv2Fs8u6GKmDE",
            // "key": "jNeGtPFrv2Fs8u6GKmDE",
            // "startAt": null,
            // "endAt": null
        },
        {
            "idDoc": "900484797",
            // "createdAt": "21-Feb-2024 15:47",
            "active": true,
            "fullname": "FONDO DE EMPLEADOS DEL DEPARTAMENTO ADMINISTRATIVO DE LA DEFENSORIA DEL ESPACIO PUBLICO FONDADEP",
            "phone": "3822510",
            "createdBy": "admin",
            "email": "fondadep@gmail.com",
            // "id": "gOhQ4LhtnMMrCeKUnPty",
            // "key": "gOhQ4LhtnMMrCeKUnPty",
            // "startAt": null,
            // "endAt": null
        },
        {
            "createdBy": "admin",
            "idDoc": "800102216",
            "email": "fecampestre@hotmail.com",
            "phone": "3336000",
            // "createdAt": "21-Feb-2024 15:47",
            "fullname": "FONDO DE EMPLEADOS DEL CLUB CAMPESTRE DE CALI",
            "active": true,
            // "id": "8obqMXMY1z2ZoyTpQVbp",
            // "key": "8obqMXMY1z2ZoyTpQVbp",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "contabilidad@feval.com.co",
            "phone": "7426333",
            "active": true,
            // "createdAt": "21-Feb-2024 15:46",
            "fullname": "FONDO DE EMPLEADOS DE VIVIENDA Y AHORRO DE ALPINA Y SU SIGLA SERA FEVAL",
            "createdBy": "admin",
            "idDoc": "860041580",
            // "id": "8QSFyd8R4hgf27BEmBbN",
            // "key": "8QSFyd8R4hgf27BEmBbN",
            // "startAt": null,
            // "endAt": null
        },
        {
            "createdBy": "admin",
            "fullname": "FONDO DE EMPLEADOS DE VIVIENDA Y AHORRO DE ALPINA Y SU SIGLA SERA FEVAL",
            "idDoc": "860041580",
            "active": true,
            // "createdAt": "21-Feb-2024 15:46",
            "phone": "7426333",
            "email": "contabilidad@feval.com.co",
            // "id": "osLVkwffAmgGRgB1v6mw",
            // "key": "osLVkwffAmgGRgB1v6mw",
            // "startAt": null,
            // "endAt": null
        },
        {
            "active": true,
            "idDoc": "901084948",
            "phone": "4049080",
            // "createdAt": "21-Feb-2024 15:45",
            "fullname": "FONDO DE EMPLEADOS DE TELEPERFORMANCE COLOMBIA",
            "createdBy": "admin",
            "email": "sandra.gonzalezgiraldo@teleperformance.com",
            // "id": "7ikklMQfg85vNPpAoccO",
            // "key": "7ikklMQfg85vNPpAoccO",
            // "startAt": null,
            // "endAt": null
        },
        {
            "idDoc": "860515448",
            "email": "fonserviciodidacol@gmail.com",
            "phone": "4238300",
            "createdBy": "admin",
            "fullname": "FONDO DE EMPLEADOS DE SERVICIO DIDACOL LTDA",
            // "createdAt": "21-Feb-2024 15:45",
            "active": true,
            // "id": "hBrUZgwScj72VGfyvjJ0",
            // "key": "hBrUZgwScj72VGfyvjJ0",
            // "startAt": null,
            // "endAt": null
        },
        {
            "idDoc": "860515813",
            "email": "fondar@rcntv.com",
            // "createdAt": "21-Feb-2024 15:44",
            "active": true,
            "fullname": "FONDO DE EMPLEADOS DE RCN TELEVISION",
            "phone": "8245800",
            "createdBy": "admin",
            // "id": "TVeHkSWDMUYQhpwp5Ci9",
            // "key": "TVeHkSWDMUYQhpwp5Ci9",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "femex@metrex.com.co",
            // "createdAt": "21-Feb-2024 15:43",
            "active": true,
            "fullname": "FONDO DE EMPLEADOS DE METREX SA FEMEX",
            "createdBy": "admin",
            "idDoc": "900199479",
            "phone": "7451054",
            // "id": "L92hyYKcV4ADKqEwF3Le",
            // "key": "L92hyYKcV4ADKqEwF3Le",
            // "startAt": null,
            // "endAt": null
        },
        {
            "createdBy": "admin",
            "phone": "6343291",
            "fullname": "FONDO DE EMPLEADOS DE LA UNVERSIDAD CATOLICA DEL ORIENTE \"FEDEUCO\"",
            "active": true,
            // "createdAt": "21-Feb-2024 15:43",
            "idDoc": "811010755",
            "email": "fedeuco@uco.edu.co",
            // "id": "4m3eXzmxwNmchrybXevz",
            // "key": "4m3eXzmxwNmchrybXevz",
            // "startAt": null,
            // "endAt": null
        },
        {
            "fullname": "FONDO DE EMPLEADOS DE LA SUPERINTENDENCIA DE SOCIEDADES",
            "createdBy": "admin",
            "active": true,
            "idDoc": "860062437",
            "phone": "2201000",
            "email": "gerenciafess@supersociedades.gov.co",
            // "createdAt": "21-Feb-2024 15:43",
            // "id": "5i01syKKaJbQoYVy3pLY",
            // "key": "5i01syKKaJbQoYVy3pLY",
            // "startAt": null,
            // "endAt": null
        },
        {
            "idDoc": "800073081",
            "email": "gerencia@felitaxi.com.co",
            "phone": "5925200",
            "fullname": "FONDO DE EMPLEADOS DE HELITAXI",
            "active": true,
            "createdBy": "admin",
            // "createdAt": "21-Feb-2024 15:42",
            // "id": "eIpxiGwvJ3myUCpQ6VNK",
            // "key": "eIpxiGwvJ3myUCpQ6VNK",
            // "startAt": null,
            // "endAt": null
        },
        {
            "fullname": "FONDO DE EMPLEADOS DE FLEXPORT DE COLOMBIA SIGLA FONEMFLEX",
            "email": "flxasisger@fonemflex.com.co",
            "idDoc": "800196423",
            "createdBy": "admin",
            "phone": "8259189",
            "active": true,
            // "createdAt": "21-Feb-2024 15:42",
            // "id": "bZ8xWLbriGoEXQfXOw8E",
            // "key": "bZ8xWLbriGoEXQfXOw8E",
            // "startAt": null,
            // "endAt": null
        },
        {
            // "createdAt": "21-Feb-2024 15:41",
            "active": true,
            "fullname": "FONDO DE EMPLEADOS DE CEMEX COLOMBIA",
            "email": "contabilidad@fecem.com",
            "createdBy": "admin",
            "idDoc": "830035448",
            "phone": "6039605",
            // "id": "i1flEvqQjHJc2lUfGi2h",
            // "key": "i1flEvqQjHJc2lUfGi2h",
            // "startAt": null,
            // "endAt": null
        },
        {
            "createdBy": "admin",
            "active": true,
            "fullname": "FONDO DE EMPLEADOS DE BANCOLOMBIA CORBANCO",
            "email": "corbanco@corbanco.com",
            "phone": "3380523",
            "idDoc": "860014736",
            // "createdAt": "21-Feb-2024 15:41",
            // "id": "XlzYfBiUGyN1x4z40IVY",
            // "key": "XlzYfBiUGyN1x4z40IVY",
            // "startAt": null,
            // "endAt": null
        },
        {
            // "createdAt": "21-Feb-2024 15:40",
            "createdBy": "admin",
            "phone": "3334164",
            "active": true,
            "email": "foncamaras@gmail.com",
            "fullname": "FONDO DE EMPLEADOS CAMARAS DE COMERCIO",
            "idDoc": "800184776",
            // "id": "Y4NGoTjLFmQBLXICPQ8l",
            // "key": "Y4NGoTjLFmQBLXICPQ8l",
            // "startAt": null,
            // "endAt": null
        },
        {
            "createdBy": "admin",
            "phone": "7469804",
            "fullname": "FONDO DE EMPLEADOS BAYER(FEBAY)",
            "idDoc": "860040746",
            "email": "elena.pachon@febay.co",
            "active": true,
            // "createdAt": "21-Feb-2024 15:38",
            // "id": "WyJDJZ2O0lwI05duQzH6",
            // "key": "WyJDJZ2O0lwI05duQzH6",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "sandra.salamanca@fe3mcolombia.com",
            "phone": "6017550255",
            // "createdAt": "21-Feb-2024 15:38",
            "createdBy": "admin",
            "idDoc": "860040473",
            "active": true,
            "fullname": "FONDO DE EMPLEADOS 3M COLOMBIA",
            // "id": "zkoKUEupSz6MfrSdIM6N",
            // "key": "zkoKUEupSz6MfrSdIM6N",
            // "startAt": null,
            // "endAt": null
        },
        {
            "phone": "5951630",
            "createdBy": "admin",
            "fullname": "FONALBATEQ",
            "active": true,
            // "createdAt": "21-Feb-2024 15:38",
            "email": "fondo@albateq.com",
            "idDoc": "832005197",
            // "id": "WiamgBW13jYaBVyQ5cJg",
            // "key": "WiamgBW13jYaBVyQ5cJg",
            // "startAt": null,
            // "endAt": null
        },
        {
            "fullname": "ESTUCHES Y EMPAQUES LTDA",
            "idDoc": "900197250",
            "email": "ventas@estuchesyempaques.net",
            "phone": "2509485",
            "createdBy": "admin",
            "active": true,
            // "createdAt": "21-Feb-2024 15:37",
            // "id": "my39A24F5ZQhflNasMZO",
            // "key": "my39A24F5ZQhflNasMZO",
            // "startAt": null,
            // "endAt": null
        },
        {
            "email": "edificiocaobos147ph@gmail.com",
            "createdBy": "admin",
            "active": true,
            "phone": "454545",
            // "createdAt": "21-Feb-2024 15:37",
            "idDoc": "900108416",
            "fullname": "EDIFICIO CAOBOS 147",
            // "id": "k2wDYiX2y5QRmlWE0Lcv",
            // "key": "k2wDYiX2y5QRmlWE0Lcv",
            // "startAt": null,
            // "endAt": null
        },
        {
            "idDoc": "860533452",
            // "createdAt": "21-Feb-2024 15:36",
            "phone": "6059323",
            "createdBy": "admin",
            "fullname": "CORPORACION FONDO DE EMPLEADOS DE LA INDUSTRIA PETROLERA COLOMBIANA \"CORPECOL\"",
            "email": "secretaria@corpecol.com",
            "active": true,
            // "id": "MsnPlJwldCKvJBPEYtMk",
            // "key": "MsnPlJwldCKvJBPEYtMk",
            // "startAt": null,
            // "endAt": null
        },
        {
            // "createdAt": "21-Feb-2024 15:34",
            "email": "facturacion@rapicredit.com",
            "idDoc": "900564668",
            "active": true,
            "createdBy": "admin",
            "fullname": "COMPANIA DE CREDITOS RAPIDOS S A S",
            "phone": "7968697",
            // "id": "bJrHb4cBgb4lvGlh2Ial",
            // "key": "bJrHb4cBgb4lvGlh2Ial",
            // "startAt": null,
            // "endAt": null
        },
        {
            "idDoc": "830001688",
            "fullname": "COMERCIALIZADORA AZHARES LTDA",
            "createdBy": "admin",
            "email": "azhares2015@gmail.com",
            // "createdAt": "21-Feb-2024 15:34",
            "active": true,
            "phone": "7597777",
            // "id": "RnPrI6UyuIGd7pgNQcNS",
            // "key": "RnPrI6UyuIGd7pgNQcNS",
            // "startAt": null,
            // "endAt": null
        },
        {
            "idDoc": "900667685",
            "fullname": "ASOCIACION GREMIAL DE MEDICOS ESPECIALISTAS EN CUIDADO CRITICO",
            "active": true,
            "createdBy": "admin",
            "email": "contabilidad_amecri2017@hotmail.com",
            "phone": "7429261",
            // "createdAt": "21-Feb-2024 15:33",
            // "id": "5VnAQGOPJMkBupHF3Dss",
            // "key": "5VnAQGOPJMkBupHF3Dss",
            // "startAt": null,
            // "endAt": null
        },
        {
            "fullname": "ADVANCE BUSINESS S A S",
            "active": true,
            "email": "analista.contable@heritagegroup.com.co",
            // "createdAt": "21-Feb-2024 15:33",
            "idDoc": "900299977",
            "createdBy": "admin",
            "phone": "",
            // "id": "VciNvtRsEplbCGlsQDr9",
            // "key": "VciNvtRsEplbCGlsQDr9",
            // "startAt": null,
            // "endAt": null
        },
        {
            "fullname": "FONDO DE EMPLEADOS Y PENSIONADOS DEL SECTOR ENERGETICO",
            "email": "fodecun@gmail.com",
            // "createdAt": "21-Feb-2024 15:32",
            "createdBy": "admin",
            "idDoc": "860025032",
            "phone": "5659788",
            "active": true,
            // "id": "2zef0vy4QujosCf9yBD5",
            // "key": "2zef0vy4QujosCf9yBD5",
            // "startAt": null,
            // "endAt": null
        },
        {
            "active": true,
            // "createdAt": "21-Feb-2024 15:32",
            "fullname": "FONDO DE EMPLEADOS DE SANTA REYES S.A. FONESA",
            "idDoc": "900391560",
            "phone": "2264161",
            "email": "asistente@fonesa.com.co",
            "createdBy": "admin",
            // "id": "CBlxwT1XXyEo2GCIgT0A",
            // "key": "CBlxwT1XXyEo2GCIgT0A",
            // "startAt": null,
            // "endAt": null
        },
        {
            "phone": "3156495865",
            "email": "contabilidad@fondomsd.com.co",
            "fullname": "FONDO DE EMPLEADOS DE MERCK SHARP & DOHME COLOMBIA FONDOFROSST",
            "idDoc": "860022308",
            "createdBy": "admin",
            "active": true,
            // "createdAt": "21-Feb-2024 15:31",
            // "id": "Y5HZt8fF77CsDsxZRA5s",
            // "key": "Y5HZt8fF77CsDsxZRA5s",
            // "startAt": null,
            // "endAt": null
        },
        {
            "fullname": "FONDO DE EMPLEADOS DE LABORATORIOS BUSSIE",
            // "createdAt": "21-Feb-2024 15:31",
            "active": true,
            "email": "fondo.empleados@bussie.com.co",
            "phone": "3351135",
            "createdBy": "admin",
            "idDoc": "800099407",
            // "id": "1N52o1EotDa4im8tXfpb",
            // "key": "1N52o1EotDa4im8tXfpb",
            // "startAt": null,
            // "endAt": null
        },
        {
            "active": true,
            // "createdAt": "21-Feb-2024 15:30",
            "idDoc": "830022270",
            "email": "wsalcedo@superservicios.gov.co",
            "phone": "6913005",
            "createdBy": "admin",
            "fullname": "FONDO DE EMPLEADOS DE LA SUPERINTENDENCIA DE SERVICIOS PUBLICOS DOMICILIARIOS \"FESSER\"",
            // "id": "cpfgsbWY9xea65Uy7vfL",
            // "key": "cpfgsbWY9xea65Uy7vfL",
            // "startAt": null,
            // "endAt": null
        },
        {
            "idDoc": "830142724",
            "active": true,
            "email": "fondo.tecnacril@faber-castell.com.co",
            "createdBy": "admin",
            "phone": "4204084",
            // "createdAt": "21-Feb-2024 15:29",
            "fullname": "FONDO DE EMPLEADOS DE AW FABER -CASTELL TECNACRIL",
            // "id": "iMyEa9uWO6ELOwEZAgcR",
            // "key": "iMyEa9uWO6ELOwEZAgcR",
            // "startAt": null,
            // "endAt": null
        },
        {
            "idDoc": "860049210",
            "active": true,
            "createdBy": "admin",
            "phone": "4204084",
            // "createdAt": "21-Feb-2024 15:29",
            "email": "860049210@factureinbox.co",
            "fullname": "A.W. FABER-CASTELL COLOMBIA LTDA",
            // "id": "BaQwVXAGwMB99wh81kT5",
            // "key": "BaQwVXAGwMB99wh81kT5",
            // "startAt": null,
            // "endAt": null
        },
        {
            "fullname": "",
            "active": true,
            "email": "info@e-techne.com",
            "idDoc": "",
            // "createdAt": "21-Feb-2024 15:20",
            "createdBy": "admin",
            "phone": "",
            // "id": "6HMjGQGbEI8cgDLyNZqo",
            // "key": "6HMjGQGbEI8cgDLyNZqo",
            // "startAt": null,
            // "endAt": null
        },
        {
            "phone": "3005165832",
            "active": true,
            "createdBy": "admin",
            "fullname": "Lorena Serrano",
            "idDoc": "35427523",
            // "createdAt": "21-Feb-2024 15:19",
            "email": "lorenita.serrano@gmail.com",
            // "id": "vUFQD9i6x3BNf0mbsvZM",
            // "key": "vUFQD9i6x3BNf0mbsvZM",
            // "startAt": null,
            // "endAt": null
        },
        {
            "fullname": "FONDO DE EMPLEADOS GALENICA FONDEGAL",
            "createdBy": "admin",
            "email": "marioalarconcp@gmail.com",
            "phone": "7465556",
            "idDoc": "830089681",
            "active": true,
            // "createdAt": "21-Feb-2024 15:17",
            // "id": "5sewqD73KyymiY4K1rfp",
            // "key": "5sewqD73KyymiY4K1rfp",
            // "startAt": null,
            // "endAt": null
        },
        {
            "idDoc": "132456789",
            "createdBy": "admin",
            "email": "alvaro.salazar@congroupsas.com",
            "phone": "31500000023",
            "active": true,
            "fullname": "Alvaro Salazar",
            // "createdAt": "21-Feb-2024 15:13",
            // "id": "krKypfSoXgKjMEpR1ZZH",
            // "key": "krKypfSoXgKjMEpR1ZZH",
            // "startAt": null,
            // "endAt": null
        },
        {
            "idDoc": "12313",
            "fullname": "Congroup WEB",
            // // "createdAt": "21-Feb-2024 14:28",
            "phone": "123456",
            "email": "congroupweb@gmail.com",
            "active": true,
            "createdBy": "admin",
            // "id": "0JFYd3hVcmpFgVLMG365",
            // "key": "0JFYd3hVcmpFgVLMG365",
            // "startAt": null,
            // "endAt": null
        }
    ]

}

export default contentConstants;