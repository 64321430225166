import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchArticles, fetchMedia, fetchServices, stsChange } from "../slices/lorettaSlice";
import { Col, Row } from "reactstrap";
import LorettaContentMin from "../../util/components/LorettaContentMin";
import { iconArrowUpSquareFill, iconFill } from "../../util/forms.util";

const News = () => {

    const dispatch = useDispatch();
    const { news } = useSelector((state) => state.loretta);

    useEffect(() => {
        if (!news) dispatch(fetchMedia())
    }, [])

    // const handleContent = (nav) => {
    //     dispatch(stsChange({ name: 'nav', value: nav }))
    //   }

    return <div>        
        <Row>
            <Col className="">
                <h4>Boletines</h4>
                <Row

                >
                    {news?.map((item, i) => {
                        return <div className="d-flex flex-column flex-md-row align-items-center" key={`divnew${i}`} id={`divnew${i}`}>
                            <div className="list-group" >
                                <a href={`detail-new?coll=content&id=${item.id}`} target="_blank" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                                {/* <a onClick={handleContent('detail-new')} target="_blank" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true"> */}
                                    {iconArrowUpSquareFill(20)}
                                    <div className="d-flex gap-2 w-100 justify-content-between">
                                        <div>
                                            <p className="mb-0">{item.tittle}</p>                                            
                                        </div>                                        
                                    </div>
                                </a>
                            </div>
                        </div>
                    })}
                </Row>


            </Col>
        </Row>
    </div>
}

export default News;