import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import pdfFile from './manual_seguridad.pdf'

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
});

const LorettaPDFViewer = () => {

    const onDocumentSuccess = ({numPages}) => {
        //console.log(numPages)
    }

    return <Document file={pdfFile} onLoadSuccess={onDocumentSuccess}>
        <Page pageNumber={1}></Page>
        {/* <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <Text>Section #1</Text>
            </View>
            <View style={styles.section}>
                <Text>Section #2</Text>
            </View>
        </Page> */}
    </Document>
}

export default LorettaPDFViewer;