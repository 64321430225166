import React, { Fragment, useEffect } from "react";

import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form'
import InputForm from "./LttInputForm";
import { commonConstants } from "../constants/common.constants";


const LttCRUDForm = (props) => {

  const { obj, readOnly, formFields, urlList } = props;

  const values = { ...obj }
  const { register, formState: { errors }, handleSubmit } =
    useForm({
      defaultValues: {
        fullname: '',
        nick: '',
        email: '',
        active: true
      },
      values
    });


  const onSubmit = (data, e) => {
    //console.log(data);
    props.onClickEvent(data);
    e.target.reset();
  }

  const fields = formFields?.map(doc => {
    return {
      field: {
        ...doc,
      },
      reg: {
        ...register(doc.controlId, {
          required: {
            value: doc.required || false,
            message: doc.requiredMsg || ''
          },
          maxLength: {
            value: doc.maxLength || 200,
            message: doc.maxLengthMsg || ''
          },
          minLength: {
            value: doc.minLength || 0,
            message: doc.minLengthMsg || ''
          },
          pattern: {
            value: new RegExp(doc.pattern || /.*(?:)/) || /.*(?:)/,
            message: doc.patternMsg || '',
          },
        })
      }
    }
  });


  return (

    <Fragment>
      
        {urlList && 
         <div className="action-bar-left">
         <Link to={urlList}>
           <span className="btn-inner--icon">
             <i className="ni ni-bold-left" />
             <i className="ni ni-bold-left" />
           </span>  Ir a la lista
         </Link></div>
        }      

     
        <form onSubmit={handleSubmit(onSubmit)}>    

          {fields.map(d => (<InputForm field={d.field} register={d.reg} error={errors[d.reg.name]}
             key={d.field.controlId}
            readOnly={readOnly} 
          />))} 

          {props.onClickEvent && 
          <div className="action-bar">
            <button type="submit" className="btn btn-primary">
              {commonConstants.LBL_SEND}
            </button>
          </div>}
        </form>

    </Fragment>
  );
}

export default LttCRUDForm;