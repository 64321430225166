// import { useDispatch, useSelector } from "react-redux";

import { Badge, Card, CardBody, CardHeader, Col, Container, Row, Table } from "reactstrap";
import List from '../data/List';
import { commonConstants } from "../../../util/constants/common.constants";
import { formConstants } from "../../../util/constants/form.constans";
import Crud from "../data/Crud";
import Send from "../data/Send";
import { useDispatch, useSelector } from "react-redux";
import { useDebugValue, useEffect, useState } from "react";
import { checkMail, fetchData, fetchEmails } from "../../slices/lorettaSlice";

const AddNew = () => {
  
  return <Container>
    <Row>
      <h1>Subir y enviar boletin</h1>
    </Row>
    <Row>
      <Col className="col-md-7 col-xs-12">
        <Card>
          <CardHeader>
            Boletin
          </CardHeader>
          <CardBody>
            {/* <Crud></Crud> */}
            <Send coll='content' idForm={formConstants.NEWS}></Send>
          </CardBody>
        </Card>
      </Col>
      <Col className="col-md-5 col-xs-12 colButtonTxt">
        <Card>
          <CardHeader>
            Clientes
          </CardHeader>
          <CardBody>
            {/* <List coll='customers' toList={true} idForm={'cust-mail'}></List> */}            
            <SelectList />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
}

export default AddNew;

const SelectList = () => {
  const { toMail } = useSelector((state) => state.loretta);
  const dispatch = useDispatch();

  // const [records, setRecords] = useState(entities);

  useEffect(() => {
    if (!toMail) dispatch(fetchEmails({ coll: "customers", toList: true }));
  }, [])

  const handleClick = (e, id, selected) =>{
    // console.log(toMail);
    dispatch(checkMail({id, selected}))
  }

  const checkAll = (selected) => {
    toMail?.map((el) => {
      dispatch(checkMail({id: el.id, selected}))
    });
  }

  return <div>
    {/* Aca va la lista <button onClick={handleClick}> OK </button> */}

    
    <Table
    >
      <tbody>
        <tr key={'all'}>
            <td scope="row">
              <div className="input-group-text">
                    <input type="checkbox" onClick={(e) => {checkAll(e.target.checked)}}/>&nbsp;
                    <label className=""><h6>Seleccionar todos</h6></label>
                  </div>
           
            </td>
          </tr>

        {toMail?.map((el, i) => 
          <tr key={i}>
            <td scope="row">
            <div className="input-group-text colButtonTxt">
                    <input type="checkbox" checked={el.selected} onClick={(e) => {handleClick(e, el.id, e.target.checked)}}/>&nbsp;
                    <label className="">{el.email}</label>
                  </div>
            </td>
          </tr>
        )}
      </tbody>

    </Table>
  </div>
}
