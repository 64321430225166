import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './util/content/site.css'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import $ from 'jquery';
import { fetchCompany, isLoggedIn, stsChange, logout } from './features/slices/lorettaSlice';
import { Button, Row, Col, Container } from 'reactstrap';
import { commonConstants } from './util/constants/common.constants';
// import {LorettaFooter, LorettaNavBar} from './util/components';
import LorettaNavBar from './util/components/LorettaNavBar';
import LorettaLoading from './util/components/LorettaLoading';
import LorettaFooter from './util/components/LorettaFooter';
import LorettaBoundary from './util/components/LorettaBoundary';
import Wrapper from './features/web/Wrapper';
import { scrollTop, whatsAppBt, breadcrumb } from './util/forms.util';
import Index from "./features/portal/Index";
import List from "./features/portal/data/List";
import Show from "./features/portal/data/Show";
import AddNew from './features/portal/news/AddNew';
import Add from './features/portal/data/Add';
import Loretta3PDF from './features/portal/data/Loretta3PDF';
import ListMedia from './features/portal/banner/ListBanner'
import ListBanner from './features/portal/banner/ListBanner';
import Web from './features/web/Web';
import Landing from './features/web/Landing';
import Login from './features/web/Login';
import DetailNew from './features/web/DetailNew';

import LorettaPDFViewer from './util/components/LorettaPDFViewer';
import LorettaPopUp from './util/components/LorettaPopUp';
const App = () => {

  const { user, nav, company, loading, info, error, online, infoLoretta } = useSelector((state) => state.loretta);
  const dispatch = useDispatch();

  // const [showVideo, setShowVideo] = useState(true);

  useEffect(() => {    
    if (!user) {
      // if(nav.length == 0){
      //   window.location = "../#popup-video";
      // }
      // if(showVideo){
      //   // window.location = "../#popup-video";
      //   setShowVideo(false);
      // }      
      dispatch(isLoggedIn());
      dispatch(fetchCompany());
    }

    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 300) {
        $('.return-to-top').fadeIn();
      } else {
        $('.return-to-top').fadeOut();
      }
    });
    $('.return-to-top').on('click', function () {
      $('html, body').animate({
        scrollTop: 0
      }, 1500);
      return false;
    });
  }, [])

  const handleContent = (nav) => {
    dispatch(stsChange({ name: 'nav', value: nav }))
  }

  const singOut = () => {
    dispatch(logout());
  }

  const handleCloseClick = () => {
    dispatch(stsChange({ name: "info", value: null }))
    dispatch(stsChange({ name: "error", value: null }))
  }

  return <>




    {
      user ?
        <>
          <LorettaNavBar handleClickEvent={handleContent} signOutEvent={singOut} user={user} />
          {breadcrumb()}
          <LorettaBoundary loading={loading} info={info} error={error} infoLoretta={infoLoretta} handleCloseClick={handleCloseClick} />
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<Index />} />
              <Route path={commonConstants.URL_NEWS_ADD} element={<AddNew />} />
              <Route path={commonConstants.URL_BANNER} element={<ListBanner />} />
              <Route path={commonConstants.URL_DATA_LIST} element={<List />} />
              <Route path={commonConstants.URL_DATA_SHOW} element={<Show />} />
              <Route path={commonConstants.URL_DATA_ADD} element={<Add />} />
            </Routes>
          </BrowserRouter>
          {/*
             
           
            <Routes>
               <Route path="*" element={<Index />} />
             <Route path={commonConstants.URL_NEWS_ADD} element={<AddNew />} />
              <Route path={commonConstants.URL_BANNER} element={<ListBanner />} />
              <Route path={commonConstants.URL_DATA_LIST} element={<List />} />
              <Route path={commonConstants.URL_DATA_SHOW} element={<Show />} />
              <Route path={commonConstants.URL_DATA_ADD} element={<Add />} /> 
            </Routes>
          </BrowserRouter>*/}
        </>
        :
        <>
         {/* <Button
          className=" button "
          color="secondary"
          type="button" href="#popup-video"
        >
          <span className="btn-inner--icon">
            <i className="ni ni-fat-add" />
          </span> PopUp
        </Button> */}

        
          <BrowserRouter>          
            <LorettaNavBar handleClickEvent={handleContent} />
            <LorettaBoundary loading={loading} info={info} error={error} infoLoretta={infoLoretta} handleCloseClick={handleCloseClick} />
            {/* <Web></Web> */}
            <Routes>
              <Route path='*' element={<Wrapper content={nav} />}></Route>
              <Route path="detail-new" element={<DetailNew />} />
              <Route path={commonConstants.URL_PDF} element={<Loretta3PDF/>} />
            </Routes>
            <div className='text-center loretta3-footer'>
            <LorettaFooter></LorettaFooter>
            </div>
          </BrowserRouter>
          {company &&
            whatsAppBt(company)
          }
        </>
    }
    {scrollTop()}


  </>
}

export default App;
