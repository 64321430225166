import { useSelector } from "react-redux";
import { Card, CardFooter, Col, Row } from "reactstrap";

const LorettaFooter = (props) => {
    // const { company } = props;
    const {company} = useSelector((state) => state.loretta);

    return <div className="loretta-footer">
        {
            company && <>
                <Row>
                    <Col><ul>
                        {company.phones?.map((item) => {
                            return <li key={`${item.label}`}>
                                <a href={`tel:${item.id}`}>
                                    <i className="fa fa-phone" aria-hidden="true" >&nbsp;</i>{`${item.label}`}
                                </a>
                            </li>
                        })}

                    
                    </ul></Col>
                    <Col>
                    {/* <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailTo:+congroup@gmail.com"></a> */}

                    <ul>
                        {company.emails?.map((item) => {
                            return <li key={`${item}`}>
                                <a href={`mailTo:${item}`}>
                                    <i className="fa fa-envelope" aria-hidden="true" >&nbsp;</i>{`${item}`}
                                </a>
                            </li>
                        })}
                        </ul>
                    </Col>

                    <Col><i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp;{`${company?.addresses[0]}`}</Col>
                </Row>

                <Row>
                    <Col>
                        <p>
                            &copy; copyright by <a href=""><i>congroupsas</i></a>.
                        </p>
                    </Col>
                    <Col>
                        <div className="social_box">
                            {/* <a href="https://co.pinterest.com/congroups/">
                <i className="fa fa-facebook" aria-hidden="true"></i>
            </a> */}
                            {/* <a href="https://www.instagram.com/congroupconsultores">
                <i className="fa fa-twitter" aria-hidden="true"></i>
            </a> */}
                            <a href="https://www.linkedin.com/in/congroup-consultores-sas-congroup">
                                <i className="fa fa-linkedin" aria-hidden="true"></i>
                            </a>
                            <a href="https://www.instagram.com/congroupconsultores">
                                <i className="fa fa-instagram" aria-hidden="true"></i>
                            </a>
                        </div>
                    </Col>
                </Row>
            </>
        }



    </div>
}

export default LorettaFooter;